import React from 'react'
import ReactDOM from 'react-dom'
import { Auth0Provider } from '@auth0/auth0-react'
import { enUS } from '@mui/material/locale'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { lightBlue } from '@mui/material/colors'
import { Chart } from 'chart.js'

import './DateExtensions'
import './NumberExtensions'

import AppAuth from './AppAuth/AppAuth'

Chart.defaults.font.family = 'Roboto, sans-serif'
Chart.defaults.font.size = 13

const theme = createTheme(
    {
        // palette: {
        //     primary: {
        //         main: lightBlue[500],
        //     },
        //     secondary: {
        //         main: lightBlue[500],
        //     },
        // },
    },
    enUS
)

if (window.location.href == 'http://localhost:3000/') {
    window.location.href = 'http://localhost:3001'
} else {
    ReactDOM.render(
        <Auth0Provider
            domain="dev-1uxy51of.us.auth0.com"
            clientId="KoV09vPPHFY9LQ4y5y8VrnzXCw0E2Tjo"
            audience="https://klept.io/"
            redirectUri={window.location.origin}
        >
            <ThemeProvider theme={theme}>
                <AppAuth />
            </ThemeProvider>
        </Auth0Provider>,
        document.getElementById('app-root')
    )
}
