import React, { useState } from 'react'
import { Line, Scatter } from 'react-chartjs-2'
import { useQuery, gql } from '@apollo/client'
import { Box, Grid, Card, CardContent, Typography, Slider } from '@mui/material'
import { Add as AddIcon, Remove as RemoveIcon } from '@mui/icons-material'

import Spinner from '../../Spinner'

function GexChart({ data, title, format, formatPrecise, color }) {
    // approx 365 days (260 trading days) of range
    const defaultChartRange = [data.length - 260, data.length - 1]
    const [chartDateRange, setChartDateRange] = useState(defaultChartRange)

    const dataToUse = data.filter((x, index) => index >= chartDateRange[0] && index <= chartDateRange[1])

    color = color || '#262525'
    const lineData = {
        labels: dataToUse.map((x) => x.date.format('MMM yy')),
        datasets: [
            {
                label: title,
                borderColor: color,
                borderWidth: 2,
                pointRadius: 0.01,
                pointHitRadius: 8,
                data: dataToUse.map((x) => x.value),
            },
        ],
    }

    const lineOptions = {
        scales: {
            y: {
                ticks: {
                    callback: format,
                },
            },
            x: {
                ticks: {
                    autoSkip: true,
                    maxTicksLimit: 10,
                },
            },
        },
        animation: {
            duration: 0,
        },
        plugins: {
            legend: {
                display: false,
            },
            title: {
                display: title,
                text: title,
                color: color,
                font: {
                    family: 'Roboto, sans-serif',
                },
            },
            tooltip: {
                callbacks: {
                    title: function (t) {
                        var value = dataToUse[t[0].dataIndex]
                        return value.date.format('MM/dd/yyyy')
                    },
                    label: function (t) {
                        var value = t.dataset.data[t.dataIndex]
                        return (formatPrecise || format)(value)
                    },
                },
            },
        },
    }

    return (
        <Grid item sm={12} md={10} lg={6} sx={{ p: 1 }}>
            <Box>
                <Line data={lineData} options={lineOptions} style={{ maxHeight: '300px' }}></Line>
            </Box>
            <Box sx={{ width: '80%', mx: 'auto' }}>
                <Slider
                    size="small"
                    min={0}
                    max={data.length - 1}
                    defaultValue={defaultChartRange}
                    onChangeCommitted={(e, v) => setChartDateRange(v)}
                    valueLabelFormat={(x) => data[x].date.format('M/d/yyyy')}
                    valueLabelDisplay="auto"
                ></Slider>
            </Box>
        </Grid>
    )
}

function GexDashboard({ data }) {
    const yesterday = data[data.length - 2]
    const today = data[data.length - 1]

    const spxPctChg = today.spx / yesterday.spx - 1

    const TopCard = ({ title, content }) => (
        <Grid item xs={12} sm={6} md={3} xl={2}>
            <Card>
                <CardContent>
                    <Typography variant="h5" component="div">
                        {title}
                    </Typography>
                    <Typography sx={{ mb: 1.5 }} color="text.secondary">
                        {content}
                    </Typography>
                </CardContent>
            </Card>
        </Grid>
    )

    return (
        <>
            <Grid container spacing={2} alignItems="center" justifyContent="center" sx={{ p: 1 }}>
                <TopCard title="Last Refresh" content={today.date.format('M/d/yyyy')} />
                <TopCard title="SPX" content={`${today.spx.toFixed(2)} (${spxPctChg < 0 ? '' : '+'}${spxPctChg.toPercent(2)})`} />
                <TopCard title="GEX" content={today.gex.toBigNumber(2)} />
                <TopCard title="VEX" content={today.vex.toBigNumber(2)} />
            </Grid>
            <Grid container spacing={2} alignItems="start" justifyContent="center" sx={{ p: 1 }}>
                <GexChart
                    title="GEX"
                    color="#e6b31a"
                    format={(val) => val.toBigNumber(0)}
                    formatPrecise={(val) => val.toBigNumber(2)}
                    data={data.map((x) => {
                        return { ...x, value: x.gex }
                    })}
                />
                <GexChart
                    title="VEX"
                    color="#9e5bc6"
                    format={(val) => val.toBigNumber(0)}
                    formatPrecise={(val) => val.toBigNumber(2)}
                    data={data.map((x) => {
                        return { ...x, value: x.vex }
                    })}
                />
                <GexChart
                    title="GEX+"
                    format={(val) => val.toBigNumber(0)}
                    formatPrecise={(val) => val.toBigNumber(2)}
                    data={data.map((x) => {
                        return { ...x, value: x.gexPlus }
                    })}
                />
            </Grid>
        </>
    )
}

function Gex() {
    const { loading, error, data } = useQuery(
        gql`
            query {
                gex(order: { date: ASC }) {
                    date
                    spx
                    gex
                    vex
                    gexPlus
                }
            }
        `
    )

    if (data) {
        return <GexDashboard data={data.gex} />
    }

    if (loading) {
        return (
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                <Spinner></Spinner>
            </Box>
        )
    }

    return <Box></Box>
}

export default Gex
