import React from 'react'
import Spinner from '../Spinner'
import { Typography } from '@mui/material'

function LoadingPage() {
    return (
        <div
            style={{
                position: 'absolute',
                height: '100%',
                width: '100%',
                top: '0',
                left: '0',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                margin: '0',
                padding: '0',
            }}
        >
            <div style={{ width: 'auto' }}>
                <div style={{ textAlign: 'center' }}>
                    <Spinner width="70" height="70" />
                </div>
            </div>
            <div
                style={{
                    position: 'absolute',
                    left: '5px',
                    top: '5px',
                    color: '#ddd',
                }}
            >
                <Typography>klept.io</Typography>
            </div>
            <div
                style={{
                    position: 'absolute',
                    right: '5px',
                    bottom: '5px',
                    color: '#ddd',
                }}
            >
                <Typography>klept.io</Typography>
            </div>
        </div>
    )
}

export default LoadingPage
