import { useState, useEffect } from 'react'
import { useQuery, gql } from '@apollo/client'
import { useHistory } from 'react-router-dom'

import { Box, Link, Typography } from '@mui/material'
import { Close as CloseIcon, ArrowForward as ArrowForwardIcon, OpenInNew as OpenInNewIcon } from '@mui/icons-material'
import { DataGrid } from '@mui/x-data-grid'
import { Line } from 'react-chartjs-2'

import { SpinnerCenter, SpinnerCenterLg } from '../../Spinner'
import { useLocalStorage } from '../../Hooks'

function PopoutChartLineChart({ data, mad, earningsDates }) {
    const lineData = {
        labels: data.map((x) => x.date.format('yyyy-MM-dd')),
        datasets: [
            {
                label: 'Closing Price',
                borderColor: '#262525',
                borderWidth: 2,
                pointRadius: 0,
                pointHitRadius: 4,
                yAxisID: 'price',
                pointBackgroundColor: '#000',
                data: data.map((x) => x.close),
            },
            {
                label: 'R Value - Scored Avg',
                borderColor: '#4682B4',
                borderWidth: 1,
                pointRadius: 0,
                pointHitRadius: 4,
                yAxisID: 'scale01',
                data: data.map((x) => x.sqzmeBacktest?.scoreAvgR),
            },
            {
                label: 'Fwd 5d Avg Return',
                borderWidth: 0,
                pointRadius: 2,
                pointHitRadius: 4,
                pointBackgroundColor: '#FF0000',
                yAxisID: 'price',
                data: [
                    ...data.filter((x, i) => i < data.length - 5).map((x) => x.close + x.madSpot * x.sqzmeBacktest?.scoreAvgMad),
                    null,
                    null,
                    null,
                    null,
                    data[data.length - 1].close + data[data.length - 1].madSpot * mad,
                ],
            },
        ],
    }
    const lineOptions = {
        earningsDates,
        dataToUse: data,
        maintainAspectRatio: false,
        scales: {
            price: {
                position: 'left',
                ticks: {
                    callback: (val) => val.toFixed(0),
                },
            },
            scale01: {
                position: 'right',
                min: -1,
                max: 1,
                ticks: {
                    callback: (val) => val.toFixed(1),
                },
                title: {
                    display: false,
                },
                grid: {
                    display: false,
                },
            },
        },
        animation: {
            duration: 0,
        },
        plugins: {
            legend: {
                display: false,
            },
            title: {
                display: false,
            },
            tooltip: {
                callbacks: {
                    label: function (t) {
                        var index = t.dataIndex
                        var value = t.dataset.data[index]
                        return value.toFixed(2)
                    },
                },
            },
        },
    }

    const showEarningsPlugin = {
        // https://github.com/reactchartjs/react-chartjs-2/issues/383#issuecomment-875676241
        afterDraw: (chart) => {
            const configOptions = chart.config.options

            const topY = chart.scales.price.top
            const bottomY = chart.scales.price.bottom

            const earningsDates = configOptions.earningsDates
            const dataToUse = configOptions.dataToUse

            const datesMap = {}
            earningsDates.forEach((d) => (datesMap[d.date] = true))

            const dataset = chart.getDatasetMeta(0).data

            const { ctx } = chart

            dataset.forEach((item, index) => {
                const dataPoint = dataToUse[index]
                if (dataPoint.date in datesMap) {
                    const x = item.x

                    // draw vertical line
                    ctx.save()
                    ctx.setLineDash([2, 3])
                    ctx.beginPath()
                    ctx.moveTo(x, topY)
                    ctx.lineTo(x, bottomY)
                    ctx.lineWidth = 1
                    ctx.strokeStyle = '#1C2128'
                    ctx.stroke()
                    ctx.restore()
                }
            })
        },
    }

    const plugins = [showEarningsPlugin]

    return (
        <Box sx={{ height: '100%', width: '100%' }}>
            <Line data={lineData} options={lineOptions} plugins={plugins} height={null} width={null} />
        </Box>
    )
}

function PopoutChart({ ticker, mad, closePopoutChart }) {
    const history = useHistory()

    const threeMonthsAgo = new Date()
    threeMonthsAgo.setUTCMonth(threeMonthsAgo.getUTCMonth() - 6)
    const dateFilter = `${threeMonthsAgo.format('yyyy-MM-dd')}T00:00:00.000Z`

    const { loading, error, data } = useQuery(
        gql`
            query ($ticker: String!, $date: DateTime!) {
                sqzme(where: { ticker: { eq: $ticker }, date: { gt: $date } }, order: { date: ASC }) {
                    date
                    close
                    madSpot
                    sqzmeBacktest {
                        scoreAvgR
                        scoreAvgMad
                    }
                }
            }
        `,
        {
            variables: {
                ticker: ticker,
                date: dateFilter,
            },
        }
    )

    const { data: earningsData } = useQuery(
        gql`
            query getEarnings($ticker: String, $date: DateTime!) {
                earnings(where: { ticker: { eq: $ticker }, date: { gt: $date } }) {
                    date
                }
            }
        `,
        {
            variables: {
                ticker: ticker,
                date: dateFilter,
            },
        }
    )

    const { data: quandlData } = useQuery(
        gql`
            query getQuandlTickerInfo($ticker: String) {
                quandlTicker(where: { ticker: { eq: $ticker } }) {
                    name
                    exchange
                }
            }
        `,
        {
            variables: {
                ticker: ticker,
            },
        }
    )

    let now = new Date()
    let nextEarningsDate = null
    earningsData?.earnings?.forEach((x) => {
        if (x.date > now && (nextEarningsDate == null || x.date < nextEarningsDate)) nextEarningsDate = x.date
    })

    let daysToNextEarnings = Math.round((nextEarningsDate - now) / (1000 * 60 * 60 * 24))

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch', height: '100%', width: '100%' }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%' }}>
                <Typography variant="h4">{ticker}</Typography>
                <Box sx={{ display: 'flex', flexDirection: 'column', fontSize: '0.8rem', marginLeft: 1 }}>
                    <Typography variant="span">{quandlData?.quandlTicker?.length >= 1 ? quandlData.quandlTicker[0].name : ''}</Typography>
                    <Typography variant="span">
                        {nextEarningsDate ? `Next earnings: ${nextEarningsDate.format('M/d/yy')} (${daysToNextEarnings} days)` : null}
                    </Typography>
                </Box>
                <Box sx={{ margin: 'auto' }}></Box>

                <Box
                    sx={{
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        '&>*': {
                            height: '1.5rem',
                            marginLeft: 2,
                            cursor: 'pointer',
                        },
                    }}
                >
                    <Link sx={{ marginLeft: 2 }} title="Open in YahooFinance" href={`https://finance.yahoo.com/quote/${ticker}`} target="_blank">
                        <OpenInNewIcon />
                    </Link>
                    <ArrowForwardIcon title="View detailed SqueezeMetrics dashboard" onClick={() => history.push(`/s/${ticker}/sqzme`)} />
                    <CloseIcon onClick={() => closePopoutChart()} />
                </Box>
            </Box>
            <Box sx={{ height: '100%' }}>
                {loading ? <SpinnerCenter /> : <PopoutChartLineChart data={data.sqzme} mad={mad} earningsDates={earningsData?.earnings || []} />}
            </Box>
        </Box>
    )
}

function TableContainer({ data }) {
    const [savedTicker, setSavedTicker] = useLocalStorage('sqzme.scanner.ticker', null)
    const [sortModel, setSortModel] = useLocalStorage('sqzme.scanner.sortModel', [])
    const [filterModel, setFilterModel] = useLocalStorage('sqzme.scanner.filterModel', { items: [], linkOperator: 'And' })
    const [selectedRow, setSelectedRow] = useState(data.length > 0 ? data[0] : null)

    useEffect(() => {
        if (savedTicker) {
            data.forEach((x) => {
                if (savedTicker === x.ticker) {
                    setSelectedRow(x)
                }
            })
        }
    }, [])

    function getColor(num) {
        if (num > 0) return 'green'
        if (num < 0) return 'red'
        return 'black'
    }

    function renderPercentChange(params) {
        return (
            <Typography color={getColor(params.value)} fontSize="0.875rem">
                {params.value > 0 ? '+' : null}
                {params.value?.toPercent(1)}
            </Typography>
        )
    }

    const columns = [
        { field: 'ticker', headerName: 'Ticker', flex: 1 },
        { field: 'close', headerName: 'Close', flex: 1, valueFormatter: (params) => params.value?.toFixed(2), type: 'number' },
        { field: 'marketCap', headerName: 'MktCap', flex: 1, valueFormatter: (params) => params.value?.toBigNumber(1), type: 'number' },
        { field: 'scoreAvgR', headerName: 'R', flex: 1, valueFormatter: (params) => params.value?.toFixed(2), type: 'number' },
        { field: 'fwd5dExpectedMad', headerName: '5d ER (MAD)', flex: 1, valueFormatter: (params) => params.value?.toFixed(2), type: 'number' },
        {
            field: 'fwd5dExpectedPct',
            headerName: '5d ER',
            flex: 1,
            renderCell: renderPercentChange,
            type: 'number',
        },
        {
            field: 'implied5dPct',
            headerName: 'Imp',
            flex: 1,
            renderCell: renderPercentChange,
            type: 'number',
        },
    ]

    return (
        <Box sx={{ display: 'flex', height: '100%', width: '100%' }}>
            <Box
                sx={{
                    width: selectedRow ? '40%' : '100%',
                    height: '100%',
                    display: 'flex',
                }}
            >
                <DataGrid
                    rows={data.map((x) => {
                        return { ...x, implied5dPct: x.fwd5dExpectedPct * x.scoreAvgR }
                    })}
                    columns={columns}
                    sortModel={sortModel}
                    filterModel={filterModel}
                    onSortModelChange={(newModel) => setSortModel(newModel)}
                    onFilterModelChange={(newModel) => setFilterModel(newModel)}
                    selectionModel={selectedRow ? [selectedRow.ticker] : []}
                    getRowId={(x) => x.ticker}
                    onRowClick={(r) => {
                        setSelectedRow(r.row)
                        setSavedTicker(r.row.ticker)
                    }}
                    density="compact"
                    hideFooterSelectedRowCount
                    sx={{
                        border: 'none',
                        borderRadius: 0,
                        '& .MuiDataGrid-root': {
                            border: 'none',
                            borderRadius: 0,
                        },
                        '& .MuiDataGrid-virtualScroller': {
                            scrollbarColor: '#eee #fff',
                            scrollbarWidth: 'thin',
                        },
                        '& .MuiDataGrid-virtualScroller::-webkit-scrollbar': {
                            width: '10px',
                        },
                        '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track': {
                            background: '#fff',
                            borderLeft: '1px solid #eee',
                        },
                        '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb': {
                            background: '#eee',
                        },
                        '& .MuiDataGrid-row': {
                            cursor: 'pointer',
                        },
                        '& .MuiDataGrid-cell:focus': {
                            outline: 'none',
                        },
                        '& .MuiDataGrid-row.Mui-selected': {
                            backgroundColor: 'rgb(25 118 210 / 12%)',
                        },
                    }}
                />
            </Box>
            {selectedRow ? (
                <Box sx={{ width: '60%', height: '100%', padding: 2 }}>
                    <PopoutChart ticker={selectedRow.ticker} mad={selectedRow.fwd5dExpectedMad} closePopoutChart={() => setSelectedRow(null)} />
                </Box>
            ) : null}
        </Box>
    )
}

export default function LoadingContainer() {
    var { loading, data, error } = useQuery(
        gql`
            query {
                stockScanner {
                    ticker
                    close
                    scoreAvgR
                    scoreAbsAvgR
                    scoreDate
                    fwd5dExpectedMad
                    fwd5dExpectedPct
                    marketCap
                }
            }
        `
    )

    if (loading) {
        return <SpinnerCenterLg />
    } else if (data) {
        return <TableContainer data={data.stockScanner} />
    } else {
        return <div>Error: {error.toString()}</div>
    }
}
