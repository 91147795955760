import React, { useState } from 'react'
import { Route, Switch, BrowserRouter as Router, Link, useHistory } from 'react-router-dom'

import {
    Button,
    Box,
    Drawer,
    AppBar,
    Toolbar,
    List,
    CssBaseline,
    Modal,
    Typography,
    Divider,
    IconButton,
    ListItem,
    ListItemIcon,
    ListItemText,
    ListSubheader,
    TextField,
} from '@mui/material'

import {
    Home as HomeIcon,
    ShowChart as ShowChartIcon,
    TableChart as TableChartIcon,
    Storage as StorageIcon,
    Person as PersonIcon,
    Code as CodeIcon,
    Close as CloseIcon,
    Menu as MenuIcon,
    Search as SearchIcon,
} from '@mui/icons-material'

import Home from './MiscPages/Home'
import Profile from './MiscPages/Profile'
import Hangfire from './MiscPages/Hangfire'
import Gql from './MiscPages/Gql'
import PgQueryTool from './MiscPages/PgQueryTool'

import Dix from './SqueezeMetrics/Dix'
import Gex from './SqueezeMetrics/Gex'
import Risk from './SqueezeMetrics/Risk'
import Scanner from './SqueezeMetrics/Scanner'
import SqzmePdfs from './SqueezeMetrics/Pdfs'
import Sqzme from './Symbol/Sqzme'
import { useEffect } from 'react'
import { useLocalStorage } from '../Hooks'

const RouterTitle = ({ path, exact, title }) => (
    <Route exact={exact} path={path}>
        <Typography variant="h6" noWrap component="div">
            {title}
        </Typography>
    </Route>
)

const GlobalSearchModal = ({ open, handleClose }) => {
    const [search, setSearch] = useState('')
    const history = useHistory()

    const boxStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        borderRadius: 2.5,
        boxShadow: 24,
        p: 2.5,
    }

    return (
        <Modal open={open} onClose={handleClose}>
            <Box sx={boxStyle}>
                <Button sx={{ position: 'absolute', left: '0', top: '0' }} onClick={handleClose}>
                    <CloseIcon fontSize="small"></CloseIcon>
                </Button>
                <Box sx={{ height: '15px' }}></Box>
                <TextField
                    value={search}
                    sx={{ width: '100%' }}
                    variant="outlined"
                    label="Symbol Search"
                    autoFocus
                    onFocus={(event) => event.target.select()}
                    onChange={(event) => {
                        const { value } = event.target
                        let newValue = value?.toUpperCase() || ''
                        newValue = newValue.replace(/[^a-zA-Z]/gm, '')
                        setSearch(newValue)
                    }}
                    onKeyDown={(event) => {
                        if (event.key == 'Enter' && search) {
                            history.push(`/s/${search}/sqzme`)
                            event.preventDefault()
                            handleClose()
                        }
                    }}
                ></TextField>
            </Box>
        </Modal>
    )
}

function AppCore({ accessToken }) {
    const [drawerOpen, setDrawerOpen] = useState(false)
    const [globalSearchOpen, setGlobalSearchOpen] = useState(false)

    const [redirectUrl, setRedirectUrl] = useLocalStorage('login.redirectUrl', null)
    const history = useHistory()
    if (redirectUrl) {
        history.push(redirectUrl)
        setRedirectUrl(null)
    }

    const globalKeyDownHandler = (event) => {
        if (!globalSearchOpen && event.ctrlKey && event.key === ' ') setGlobalSearchOpen(true)
    }

    useEffect(() => {
        document.addEventListener('keydown', globalKeyDownHandler)
        return () => document.removeEventListener('keydown', globalKeyDownHandler)
    }, [])

    const toggleDrawer = () => {
        setDrawerOpen(!drawerOpen)
    }

    const NavLinkItem = ({ path, title, icon }) => (
        <ListItem button component={Link} to={path} onClick={() => setDrawerOpen(false)}>
            <ListItemIcon>{React.createElement(icon)}</ListItemIcon>
            <ListItemText primary={title} />
        </ListItem>
    )

    return (
        <>
            <Box sx={{ display: 'flex' }} onKeyPress={globalKeyDownHandler}>
                <CssBaseline />
                <AppBar position="fixed" open={drawerOpen} sx={{ boxShadow: 'none' }}>
                    <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <IconButton color="inherit" aria-label="open drawer" onClick={toggleDrawer} edge="start" sx={{ marginRight: '36px' }}>
                            <MenuIcon />
                        </IconButton>
                        <Switch>
                            <RouterTitle exact path="/profile" title="Profile" />
                            <RouterTitle exact path="/hf" title="Hangfire" />
                            <RouterTitle exact path="/gql" title="GraphQL" />
                            <RouterTitle exact path="/sqzme/dix" title="Dix Dashboard" />
                            <RouterTitle exact path="/sqzme/gex" title="Gex Dashboard" />
                            <RouterTitle exact path="/sqzme/risk" title="Risk Report" />
                            <RouterTitle exact path="/sqzme/pdfs" title="Sqzme Pdfs" />
                            <RouterTitle exact path="/sqzme/scanner" title="Stock Scanner" />
                            <RouterTitle exact path="/pg" title="PgQueryTool" />
                        </Switch>

                        <Button sx={{ color: 'white' }} onClick={() => setGlobalSearchOpen(true)} title="Shortcut: Ctrl+Enter">
                            <SearchIcon />
                        </Button>
                    </Toolbar>
                </AppBar>
                <Drawer open={drawerOpen} anchor="left" onClose={toggleDrawer}>
                    <List sx={{ minWidth: '240px' }}>
                        <NavLinkItem path="/" title="Home" icon={HomeIcon} />
                        <NavLinkItem path="/hf" title="Hangfire" icon={StorageIcon} />
                        <NavLinkItem path="/gql" title="GraphQL" icon={CodeIcon} />
                        <NavLinkItem path="/pg" title="PostgreSQL" icon={CodeIcon} />
                        <NavLinkItem path="/profile" title="Profile" icon={PersonIcon} />
                        <ListSubheader>Squeeze Metrics</ListSubheader>
                        <NavLinkItem path="/sqzme/dix" title="Dix Dashboard" icon={TableChartIcon} />
                        <NavLinkItem path="/sqzme/gex" title="Gex Dashboard" icon={TableChartIcon} />
                        <NavLinkItem path="/sqzme/risk" title="Risk Report" icon={TableChartIcon} />
                        <NavLinkItem path="/sqzme/pdfs" title="Pdf Reports" icon={TableChartIcon} />
                        <NavLinkItem path="/sqzme/scanner" title="Stock Scanner" icon={TableChartIcon} />
                    </List>
                </Drawer>
                <Box
                    component="div"
                    sx={{
                        position: 'absolute',
                        height: {
                            xs: 'calc(100% - 56px)',
                            sm: 'calc(100% - 64px)',
                        },
                        width: 'calc(100% - 10px)',
                        left: '5px',
                        top: {
                            xs: '56px',
                            sm: '64px',
                        },
                    }}
                >
                    <Box sx={{ height: 'calc(100% - 1px)', width: '100%', display: 'flex', flexDirection: 'column' }}>
                        <Switch>
                            <Route exact path="/">
                                <Home />
                            </Route>
                            <Route path="/profile">
                                <Profile accessToken={accessToken} />
                            </Route>
                            <Route path="/hf">
                                <Hangfire accessToken={accessToken} />
                            </Route>
                            <Route path="/gql">
                                <Gql />
                            </Route>
                            <Route path="/sqzme/dix">
                                <Dix />
                            </Route>
                            <Route path="/sqzme/gex">
                                <Gex />
                            </Route>
                            <Route path="/sqzme/risk">
                                <Risk />
                            </Route>
                            <Route path="/sqzme/pdfs">
                                <SqzmePdfs accessToken={accessToken} />
                            </Route>
                            <Route path="/sqzme/scanner">
                                <Scanner />
                            </Route>
                            <Route exact path="/pg">
                                <PgQueryTool />
                            </Route>
                            <Route exact path="/s/:symbol/sqzme">
                                <Sqzme />
                            </Route>
                            <Route path="/">404 Not Found</Route>
                        </Switch>
                    </Box>
                </Box>
            </Box>
            <GlobalSearchModal open={globalSearchOpen} handleClose={() => setGlobalSearchOpen(false)} />
        </>
    )
}

export default function AppCoreWrapper({ accessToken }) {
    return (
        <Router>
            <AppCore accessToken={accessToken} />
        </Router>
    )
}
