
const schema = `schema {
  query: Query
  mutation: Mutation
}

type CandleWithBolBands {
  deviationsPerBand: Decimal!
  ticker: String
  open: Decimal!
  high: Decimal!
  low: Decimal!
  close: Decimal!
  movingAvg: Decimal!
  bandStdDev: Decimal!
  forwardHigh: Decimal
  forwardLow: Decimal
  upperBand: Decimal!
  lowerBand: Decimal!
  xb: Decimal!
  bandWidth: Decimal!
  timestamp: DateTime!
}

type DailyCandle {
  timestamp: DateTime!
  ticker: String
  open: Float!
  high: Float!
  low: Float!
  close: Float!
  volume: Long
}

type EarningsCalendar {
  type: EarningsType!
  ticker: String
  when: ReportTime
  date: DateTime!
  securityName: String
  exchange: String
  weight: Int
  title: String
  publishUrl: String
  publishDate: DateTime
  updatedAt: DateTime
}

type MinuteCandle {
  timestamp: Long!
  ticker: String
  open: Float!
  high: Float!
  low: Float!
  close: Float!
  volume: Long!
}

type Mutation {
  clearCache: Boolean! @authorize(apply: BEFORE_RESOLVER)
  createPgQuery(createPgQueryDto: CreatePgQueryDtoInput!): Boolean! @authorize(apply: BEFORE_RESOLVER)
  deletePgQuery(name: String!): Boolean! @authorize(apply: BEFORE_RESOLVER)
  renamePgQuery(oldName: String! newName: String!): Boolean! @authorize(apply: BEFORE_RESOLVER)
  updatePgQuery(updatePgQueryDto: UpdatePgQueryDtoInput!): Boolean! @authorize(apply: BEFORE_RESOLVER)
}

type PgQuery {
  userId: String
  fileName: String
  fileContent: String
  createdAt: DateTime!
  updatedAt: DateTime!
}

type PredictorPair {
  median: Float
  mean: Float
  meanSpot: Float
  vol: Float
  volMedian: Float
}

type QuandlDaily {
  ticker: String
  date: DateTime!
  lastUpdated: DateTime!
  eV: Decimal!
  eVEBIT: Decimal!
  eVEBITDA: Decimal!
  marketCap: Decimal!
  pB: Decimal!
  pE: Decimal!
  pS: Decimal!
}

type QuandlTicker {
  table: String
  permaTicker: Int!
  ticker: String
  name: String
  exchange: String
  isDelisted: Boolean
  category: String
  cusips: String
  sicCode: String
  sicSector: String
  sicIndustry: String
  famaSector: String
  famaIndustry: String
  sector: String
  industry: String
  scaleMarketCap: String
  scaleRevenue: String
  relatedTickers: String
  currency: String
  location: String
  lastUpdated: DateTime
  firstAdded: DateTime
  firstPriceDate: DateTime
  lastPriceDate: DateTime
  firstQuarter: DateTime
  lastQuarter: DateTime
  secFilings: String
  companySite: String
}

type Query {
  dix(where: SqzmeDixEntryFilterInput order: [SqzmeDixEntrySortInput!]): [SqzmeDixEntry] @authorize(apply: BEFORE_RESOLVER)
  gex(where: SqzmeGexEntryFilterInput order: [SqzmeGexEntrySortInput!]): [SqzmeGexEntry] @authorize(apply: BEFORE_RESOLVER)
  sqzmeBacktest(where: SqzmeBacktestFilterInput order: [SqzmeBacktestSortInput!]): [SqzmeBacktest] @authorize(apply: BEFORE_RESOLVER)
  sqzme(where: SqzmeTickerFilterInput order: [SqzmeTickerSortInput!]): [SqzmeTicker] @authorize(apply: BEFORE_RESOLVER)
  bolBands(ticker: String period: Int! = 20 deviationsPerBand: Decimal! = 2 lookaheadPeriod: Int! = 5): [CandleWithBolBands] @authorize(apply: BEFORE_RESOLVER)
  dailyCandles(where: DailyCandleFilterInput order: [DailyCandleSortInput!]): [DailyCandle] @authorize(apply: BEFORE_RESOLVER)
  earnings(where: EarningsCalendarFilterInput order: [EarningsCalendarSortInput!]): [EarningsCalendar] @authorize(apply: BEFORE_RESOLVER)
  minuteCandles(where: MinuteCandleFilterInput order: [MinuteCandleSortInput!]): [MinuteCandle] @authorize(apply: BEFORE_RESOLVER)
  quandlDaily(where: QuandlDailyFilterInput order: [QuandlDailySortInput!]): [QuandlDaily] @authorize(apply: BEFORE_RESOLVER)
  quandlDailyLatest(ticker: String!): QuandlDaily @authorize(apply: BEFORE_RESOLVER)
  quandlTicker(where: QuandlTickerFilterInput order: [QuandlTickerSortInput!]): [QuandlTicker] @authorize(apply: BEFORE_RESOLVER)
  sqlQuery(statement: String): SqlQueryResult @authorize(policy: "execute:sql", apply: BEFORE_RESOLVER)
  stockScanner(forDay: DateTime): [SqzmeStockScannerResult] @authorize(apply: BEFORE_RESOLVER)
  pgQueries: [PgQuery] @authorize(apply: BEFORE_RESOLVER)
}

type RValues {
  rValue10Day: Float
  rValue21Day: Float
  rValue42Day: Float
  rValue200Day: Float
}

type SqlQueryColumn {
  name: String
  ordinal: Int!
  dataType: String
}

type SqlQueryResult {
  isSuccessful: Boolean!
  errorMessage: String
  columns: [SqlQueryColumn]
  rows: [[Any]]
}

type SqzmeBacktest {
  ticker: String
  date: DateTime!
  sqzmeTicker: SqzmeTicker
  fwd5dDate: DateTime
  fwd5dClose: Float
  fwd5dPct: Float
  fwd5dMad: Float
  isScored: Boolean!
  scoreAvgR: Float
  scoreAvgMad: Float
  scorePredictors: [SqzmeBacktestPredictor]
  meanRValue: RValues
  medianRValue: RValues
  gD_MeanRValue: RValues
  gD_MedianRValue: RValues
  pD_MeanRValue: RValues
  pD_MedianRValue: RValues
  pG_MeanRValue: RValues
  pG_MedianRValue: RValues
  pV_MeanRValue: RValues
  pV_MedianRValue: RValues
  vD_MeanRValue: RValues
  vD_MedianRValue: RValues
  vG_MeanRValue: RValues
  vG_MedianRValue: RValues
}

type SqzmeBacktestPredictor {
  predictorPair: KindOfPredictorPair!
  madType: MadType!
  backtestDays: BacktestDays!
}

type SqzmeDixEntry {
  date: DateTime!
  spx: Decimal!
  dix: Decimal!
  gex: Decimal!
}

type SqzmeGexEntry {
  date: DateTime!
  spx: Decimal!
  spxPctChg: Decimal
  gex: Decimal!
  vex: Decimal!
  gexPlus: Decimal!
  giv: Decimal!
  givMadPct: Decimal!
  npd: Decimal!
  vgr: Decimal!
  vix: Decimal!
  vixMadPct: Decimal!
  cr: Decimal!
  su: Decimal!
  mid: Decimal!
  mo: Decimal!
  dix: Decimal
  vibe: Decimal
}

type SqzmeStockScannerResult {
  ticker: String
  close: Float!
  scoreAvgR: Float!
  scoreAbsAvgR: Float!
  fwd5dExpectedMad: Float!
  fwd5dExpectedPct: Float!
  fwd5dInferredPct: Float
  fwd5dRealizedMad: Float
  fwd5dRealizedPct: Float
  scoreDate: DateTime!
  marketCap: Float
  distSquared: Float!
}

type SqzmeTicker {
  date: DateTime!
  ticker: String
  sqzmeBacktest: SqzmeBacktest
  open: Float
  high: Float
  low: Float
  close: Float!
  volume: Float!
  iV: Float
  iV_Pct: Float
  iV_Usd: Float
  median: Float
  medianPct: Float
  mean: Float
  meanSpot: Float
  meanPct: Float
  vol: Float
  volPct: Float
  volMedian: Float
  volMedianPct: Float
  madPct: Float
  madSpot: Float
  d: Float
  g: Float
  p: Float
  v: Float
  d0: Float
  g0: Float
  p0: Float
  v0: Float
  gD: PredictorPair
  pD: PredictorPair
  pG: PredictorPair
  pV: PredictorPair
  vD: PredictorPair
  vG: PredictorPair
}

input BacktestDaysOperationFilterInput {
  eq: BacktestDays
  neq: BacktestDays
  in: [BacktestDays!]
  nin: [BacktestDays!]
}

input BooleanOperationFilterInput {
  eq: Boolean
  neq: Boolean
}

input ComparableDateTimeOperationFilterInput {
  eq: DateTime
  neq: DateTime
  in: [DateTime!]
  nin: [DateTime!]
  gt: DateTime
  ngt: DateTime
  gte: DateTime
  ngte: DateTime
  lt: DateTime
  nlt: DateTime
  lte: DateTime
  nlte: DateTime
}

input ComparableDecimalOperationFilterInput {
  eq: Decimal
  neq: Decimal
  in: [Decimal!]
  nin: [Decimal!]
  gt: Decimal
  ngt: Decimal
  gte: Decimal
  ngte: Decimal
  lt: Decimal
  nlt: Decimal
  lte: Decimal
  nlte: Decimal
}

input ComparableDoubleOperationFilterInput {
  eq: Float
  neq: Float
  in: [Float!]
  nin: [Float!]
  gt: Float
  ngt: Float
  gte: Float
  ngte: Float
  lt: Float
  nlt: Float
  lte: Float
  nlte: Float
}

input ComparableInt32OperationFilterInput {
  eq: Int
  neq: Int
  in: [Int!]
  nin: [Int!]
  gt: Int
  ngt: Int
  gte: Int
  ngte: Int
  lt: Int
  nlt: Int
  lte: Int
  nlte: Int
}

input ComparableInt64OperationFilterInput {
  eq: Long
  neq: Long
  in: [Long!]
  nin: [Long!]
  gt: Long
  ngt: Long
  gte: Long
  ngte: Long
  lt: Long
  nlt: Long
  lte: Long
  nlte: Long
}

input ComparableNullableOfDateTimeOperationFilterInput {
  eq: DateTime
  neq: DateTime
  in: [DateTime]
  nin: [DateTime]
  gt: DateTime
  ngt: DateTime
  gte: DateTime
  ngte: DateTime
  lt: DateTime
  nlt: DateTime
  lte: DateTime
  nlte: DateTime
}

input ComparableNullableOfDecimalOperationFilterInput {
  eq: Decimal
  neq: Decimal
  in: [Decimal]
  nin: [Decimal]
  gt: Decimal
  ngt: Decimal
  gte: Decimal
  ngte: Decimal
  lt: Decimal
  nlt: Decimal
  lte: Decimal
  nlte: Decimal
}

input ComparableNullableOfDoubleOperationFilterInput {
  eq: Float
  neq: Float
  in: [Float]
  nin: [Float]
  gt: Float
  ngt: Float
  gte: Float
  ngte: Float
  lt: Float
  nlt: Float
  lte: Float
  nlte: Float
}

input ComparableNullableOfInt32OperationFilterInput {
  eq: Int
  neq: Int
  in: [Int]
  nin: [Int]
  gt: Int
  ngt: Int
  gte: Int
  ngte: Int
  lt: Int
  nlt: Int
  lte: Int
  nlte: Int
}

input ComparableNullableOfInt64OperationFilterInput {
  eq: Long
  neq: Long
  in: [Long]
  nin: [Long]
  gt: Long
  ngt: Long
  gte: Long
  ngte: Long
  lt: Long
  nlt: Long
  lte: Long
  nlte: Long
}

input ComparableSingleOperationFilterInput {
  eq: Float
  neq: Float
  in: [Float!]
  nin: [Float!]
  gt: Float
  ngt: Float
  gte: Float
  ngte: Float
  lt: Float
  nlt: Float
  lte: Float
  nlte: Float
}

input CreatePgQueryDtoInput {
  name: String
  content: String
}

input DailyCandleFilterInput {
  and: [DailyCandleFilterInput!]
  or: [DailyCandleFilterInput!]
  timestamp: ComparableDateTimeOperationFilterInput
  ticker: StringOperationFilterInput
  open: ComparableSingleOperationFilterInput
  high: ComparableSingleOperationFilterInput
  low: ComparableSingleOperationFilterInput
  close: ComparableSingleOperationFilterInput
  volume: ComparableNullableOfInt64OperationFilterInput
}

input DailyCandleSortInput {
  timestamp: SortEnumType
  ticker: SortEnumType
  open: SortEnumType
  high: SortEnumType
  low: SortEnumType
  close: SortEnumType
  volume: SortEnumType
}

input EarningsCalendarFilterInput {
  and: [EarningsCalendarFilterInput!]
  or: [EarningsCalendarFilterInput!]
  type: EarningsTypeOperationFilterInput
  ticker: StringOperationFilterInput
  when: NullableOfReportTimeOperationFilterInput
  date: ComparableDateTimeOperationFilterInput
  securityName: StringOperationFilterInput
  exchange: StringOperationFilterInput
  weight: ComparableNullableOfInt32OperationFilterInput
  title: StringOperationFilterInput
  publishUrl: StringOperationFilterInput
  publishDate: ComparableNullableOfDateTimeOperationFilterInput
  updatedAt: ComparableNullableOfDateTimeOperationFilterInput
}

input EarningsCalendarSortInput {
  type: SortEnumType
  ticker: SortEnumType
  when: SortEnumType
  date: SortEnumType
  securityName: SortEnumType
  exchange: SortEnumType
  weight: SortEnumType
  title: SortEnumType
  publishUrl: SortEnumType
  publishDate: SortEnumType
  updatedAt: SortEnumType
}

input EarningsTypeOperationFilterInput {
  eq: EarningsType
  neq: EarningsType
  in: [EarningsType!]
  nin: [EarningsType!]
}

input KindOfPredictorPairOperationFilterInput {
  eq: KindOfPredictorPair
  neq: KindOfPredictorPair
  in: [KindOfPredictorPair!]
  nin: [KindOfPredictorPair!]
}

input ListFilterInputTypeOfSqzmeBacktestPredictorFilterInput {
  all: SqzmeBacktestPredictorFilterInput
  none: SqzmeBacktestPredictorFilterInput
  some: SqzmeBacktestPredictorFilterInput
  any: Boolean
}

input MadTypeOperationFilterInput {
  eq: MadType
  neq: MadType
  in: [MadType!]
  nin: [MadType!]
}

input MinuteCandleFilterInput {
  and: [MinuteCandleFilterInput!]
  or: [MinuteCandleFilterInput!]
  timestamp: ComparableInt64OperationFilterInput
  ticker: StringOperationFilterInput
  open: ComparableSingleOperationFilterInput
  high: ComparableSingleOperationFilterInput
  low: ComparableSingleOperationFilterInput
  close: ComparableSingleOperationFilterInput
  volume: ComparableInt64OperationFilterInput
}

input MinuteCandleSortInput {
  timestamp: SortEnumType
  ticker: SortEnumType
  open: SortEnumType
  high: SortEnumType
  low: SortEnumType
  close: SortEnumType
  volume: SortEnumType
}

input NullableOfReportTimeOperationFilterInput {
  eq: ReportTime
  neq: ReportTime
  in: [ReportTime]
  nin: [ReportTime]
}

input PredictorPairFilterInput {
  and: [PredictorPairFilterInput!]
  or: [PredictorPairFilterInput!]
  median: ComparableNullableOfDoubleOperationFilterInput
  mean: ComparableNullableOfDoubleOperationFilterInput
  meanSpot: ComparableNullableOfDoubleOperationFilterInput
  vol: ComparableNullableOfDoubleOperationFilterInput
  volMedian: ComparableNullableOfDoubleOperationFilterInput
}

input PredictorPairSortInput {
  median: SortEnumType
  mean: SortEnumType
  meanSpot: SortEnumType
  vol: SortEnumType
  volMedian: SortEnumType
}

input QuandlDailyFilterInput {
  and: [QuandlDailyFilterInput!]
  or: [QuandlDailyFilterInput!]
  ticker: StringOperationFilterInput
  date: ComparableDateTimeOperationFilterInput
  lastUpdated: ComparableDateTimeOperationFilterInput
  eV: ComparableDecimalOperationFilterInput
  eVEBIT: ComparableDecimalOperationFilterInput
  eVEBITDA: ComparableDecimalOperationFilterInput
  marketCap: ComparableDecimalOperationFilterInput
  pB: ComparableDecimalOperationFilterInput
  pE: ComparableDecimalOperationFilterInput
  pS: ComparableDecimalOperationFilterInput
}

input QuandlDailySortInput {
  ticker: SortEnumType
  date: SortEnumType
  lastUpdated: SortEnumType
  eV: SortEnumType
  eVEBIT: SortEnumType
  eVEBITDA: SortEnumType
  marketCap: SortEnumType
  pB: SortEnumType
  pE: SortEnumType
  pS: SortEnumType
}

input QuandlTickerFilterInput {
  and: [QuandlTickerFilterInput!]
  or: [QuandlTickerFilterInput!]
  table: StringOperationFilterInput
  permaTicker: ComparableInt32OperationFilterInput
  ticker: StringOperationFilterInput
  name: StringOperationFilterInput
  exchange: StringOperationFilterInput
  isDelisted: BooleanOperationFilterInput
  category: StringOperationFilterInput
  cusips: StringOperationFilterInput
  sicCode: StringOperationFilterInput
  sicSector: StringOperationFilterInput
  sicIndustry: StringOperationFilterInput
  famaSector: StringOperationFilterInput
  famaIndustry: StringOperationFilterInput
  sector: StringOperationFilterInput
  industry: StringOperationFilterInput
  scaleMarketCap: StringOperationFilterInput
  scaleRevenue: StringOperationFilterInput
  relatedTickers: StringOperationFilterInput
  currency: StringOperationFilterInput
  location: StringOperationFilterInput
  lastUpdated: ComparableNullableOfDateTimeOperationFilterInput
  firstAdded: ComparableNullableOfDateTimeOperationFilterInput
  firstPriceDate: ComparableNullableOfDateTimeOperationFilterInput
  lastPriceDate: ComparableNullableOfDateTimeOperationFilterInput
  firstQuarter: ComparableNullableOfDateTimeOperationFilterInput
  lastQuarter: ComparableNullableOfDateTimeOperationFilterInput
  secFilings: StringOperationFilterInput
  companySite: StringOperationFilterInput
}

input QuandlTickerSortInput {
  table: SortEnumType
  permaTicker: SortEnumType
  ticker: SortEnumType
  name: SortEnumType
  exchange: SortEnumType
  isDelisted: SortEnumType
  category: SortEnumType
  cusips: SortEnumType
  sicCode: SortEnumType
  sicSector: SortEnumType
  sicIndustry: SortEnumType
  famaSector: SortEnumType
  famaIndustry: SortEnumType
  sector: SortEnumType
  industry: SortEnumType
  scaleMarketCap: SortEnumType
  scaleRevenue: SortEnumType
  relatedTickers: SortEnumType
  currency: SortEnumType
  location: SortEnumType
  lastUpdated: SortEnumType
  firstAdded: SortEnumType
  firstPriceDate: SortEnumType
  lastPriceDate: SortEnumType
  firstQuarter: SortEnumType
  lastQuarter: SortEnumType
  secFilings: SortEnumType
  companySite: SortEnumType
}

input RValuesFilterInput {
  and: [RValuesFilterInput!]
  or: [RValuesFilterInput!]
  rValue10Day: ComparableNullableOfDoubleOperationFilterInput
  rValue21Day: ComparableNullableOfDoubleOperationFilterInput
  rValue42Day: ComparableNullableOfDoubleOperationFilterInput
  rValue200Day: ComparableNullableOfDoubleOperationFilterInput
}

input RValuesSortInput {
  rValue10Day: SortEnumType
  rValue21Day: SortEnumType
  rValue42Day: SortEnumType
  rValue200Day: SortEnumType
}

input SqzmeBacktestFilterInput {
  and: [SqzmeBacktestFilterInput!]
  or: [SqzmeBacktestFilterInput!]
  ticker: StringOperationFilterInput
  date: ComparableDateTimeOperationFilterInput
  sqzmeTicker: SqzmeTickerFilterInput
  fwd5dDate: ComparableNullableOfDateTimeOperationFilterInput
  fwd5dClose: ComparableNullableOfDoubleOperationFilterInput
  fwd5dPct: ComparableNullableOfDoubleOperationFilterInput
  fwd5dMad: ComparableNullableOfDoubleOperationFilterInput
  isScored: BooleanOperationFilterInput
  scoreAvgR: ComparableNullableOfDoubleOperationFilterInput
  scoreAvgMad: ComparableNullableOfDoubleOperationFilterInput
  scorePredictors: ListFilterInputTypeOfSqzmeBacktestPredictorFilterInput
  meanRValue: RValuesFilterInput
  medianRValue: RValuesFilterInput
  gD_MeanRValue: RValuesFilterInput
  gD_MedianRValue: RValuesFilterInput
  pD_MeanRValue: RValuesFilterInput
  pD_MedianRValue: RValuesFilterInput
  pG_MeanRValue: RValuesFilterInput
  pG_MedianRValue: RValuesFilterInput
  pV_MeanRValue: RValuesFilterInput
  pV_MedianRValue: RValuesFilterInput
  vD_MeanRValue: RValuesFilterInput
  vD_MedianRValue: RValuesFilterInput
  vG_MeanRValue: RValuesFilterInput
  vG_MedianRValue: RValuesFilterInput
}

input SqzmeBacktestPredictorFilterInput {
  and: [SqzmeBacktestPredictorFilterInput!]
  or: [SqzmeBacktestPredictorFilterInput!]
  predictorPair: KindOfPredictorPairOperationFilterInput
  madType: MadTypeOperationFilterInput
  backtestDays: BacktestDaysOperationFilterInput
}

input SqzmeBacktestSortInput {
  ticker: SortEnumType
  date: SortEnumType
  sqzmeTicker: SqzmeTickerSortInput
  fwd5dDate: SortEnumType
  fwd5dClose: SortEnumType
  fwd5dPct: SortEnumType
  fwd5dMad: SortEnumType
  isScored: SortEnumType
  scoreAvgR: SortEnumType
  scoreAvgMad: SortEnumType
  meanRValue: RValuesSortInput
  medianRValue: RValuesSortInput
  gD_MeanRValue: RValuesSortInput
  gD_MedianRValue: RValuesSortInput
  pD_MeanRValue: RValuesSortInput
  pD_MedianRValue: RValuesSortInput
  pG_MeanRValue: RValuesSortInput
  pG_MedianRValue: RValuesSortInput
  pV_MeanRValue: RValuesSortInput
  pV_MedianRValue: RValuesSortInput
  vD_MeanRValue: RValuesSortInput
  vD_MedianRValue: RValuesSortInput
  vG_MeanRValue: RValuesSortInput
  vG_MedianRValue: RValuesSortInput
}

input SqzmeDixEntryFilterInput {
  and: [SqzmeDixEntryFilterInput!]
  or: [SqzmeDixEntryFilterInput!]
  date: ComparableDateTimeOperationFilterInput
  spx: ComparableDecimalOperationFilterInput
  dix: ComparableDecimalOperationFilterInput
  gex: ComparableDecimalOperationFilterInput
}

input SqzmeDixEntrySortInput {
  date: SortEnumType
  spx: SortEnumType
  dix: SortEnumType
  gex: SortEnumType
}

input SqzmeGexEntryFilterInput {
  and: [SqzmeGexEntryFilterInput!]
  or: [SqzmeGexEntryFilterInput!]
  date: ComparableDateTimeOperationFilterInput
  spx: ComparableDecimalOperationFilterInput
  spxPctChg: ComparableNullableOfDecimalOperationFilterInput
  gex: ComparableDecimalOperationFilterInput
  vex: ComparableDecimalOperationFilterInput
  gexPlus: ComparableDecimalOperationFilterInput
  giv: ComparableDecimalOperationFilterInput
  givMadPct: ComparableDecimalOperationFilterInput
  npd: ComparableDecimalOperationFilterInput
  vgr: ComparableDecimalOperationFilterInput
  vix: ComparableDecimalOperationFilterInput
  vixMadPct: ComparableDecimalOperationFilterInput
  cr: ComparableDecimalOperationFilterInput
  su: ComparableDecimalOperationFilterInput
  mid: ComparableDecimalOperationFilterInput
  mo: ComparableDecimalOperationFilterInput
  dix: ComparableNullableOfDecimalOperationFilterInput
  vibe: ComparableNullableOfDecimalOperationFilterInput
}

input SqzmeGexEntrySortInput {
  date: SortEnumType
  spx: SortEnumType
  spxPctChg: SortEnumType
  gex: SortEnumType
  vex: SortEnumType
  gexPlus: SortEnumType
  giv: SortEnumType
  givMadPct: SortEnumType
  npd: SortEnumType
  vgr: SortEnumType
  vix: SortEnumType
  vixMadPct: SortEnumType
  cr: SortEnumType
  su: SortEnumType
  mid: SortEnumType
  mo: SortEnumType
  dix: SortEnumType
  vibe: SortEnumType
}

input SqzmeTickerFilterInput {
  and: [SqzmeTickerFilterInput!]
  or: [SqzmeTickerFilterInput!]
  date: ComparableDateTimeOperationFilterInput
  ticker: StringOperationFilterInput
  sqzmeBacktest: SqzmeBacktestFilterInput
  open: ComparableNullableOfDoubleOperationFilterInput
  high: ComparableNullableOfDoubleOperationFilterInput
  low: ComparableNullableOfDoubleOperationFilterInput
  close: ComparableDoubleOperationFilterInput
  volume: ComparableDoubleOperationFilterInput
  iV: ComparableNullableOfDoubleOperationFilterInput
  iV_Pct: ComparableNullableOfDoubleOperationFilterInput
  iV_Usd: ComparableNullableOfDoubleOperationFilterInput
  median: ComparableNullableOfDoubleOperationFilterInput
  medianPct: ComparableNullableOfDoubleOperationFilterInput
  mean: ComparableNullableOfDoubleOperationFilterInput
  meanSpot: ComparableNullableOfDoubleOperationFilterInput
  meanPct: ComparableNullableOfDoubleOperationFilterInput
  vol: ComparableNullableOfDoubleOperationFilterInput
  volPct: ComparableNullableOfDoubleOperationFilterInput
  volMedian: ComparableNullableOfDoubleOperationFilterInput
  volMedianPct: ComparableNullableOfDoubleOperationFilterInput
  madPct: ComparableNullableOfDoubleOperationFilterInput
  madSpot: ComparableNullableOfDoubleOperationFilterInput
  d: ComparableNullableOfDoubleOperationFilterInput
  g: ComparableNullableOfDoubleOperationFilterInput
  p: ComparableNullableOfDoubleOperationFilterInput
  v: ComparableNullableOfDoubleOperationFilterInput
  d0: ComparableNullableOfDoubleOperationFilterInput
  g0: ComparableNullableOfDoubleOperationFilterInput
  p0: ComparableNullableOfDoubleOperationFilterInput
  v0: ComparableNullableOfDoubleOperationFilterInput
  gD: PredictorPairFilterInput
  pD: PredictorPairFilterInput
  pG: PredictorPairFilterInput
  pV: PredictorPairFilterInput
  vD: PredictorPairFilterInput
  vG: PredictorPairFilterInput
}

input SqzmeTickerSortInput {
  date: SortEnumType
  ticker: SortEnumType
  sqzmeBacktest: SqzmeBacktestSortInput
  open: SortEnumType
  high: SortEnumType
  low: SortEnumType
  close: SortEnumType
  volume: SortEnumType
  iV: SortEnumType
  iV_Pct: SortEnumType
  iV_Usd: SortEnumType
  median: SortEnumType
  medianPct: SortEnumType
  mean: SortEnumType
  meanSpot: SortEnumType
  meanPct: SortEnumType
  vol: SortEnumType
  volPct: SortEnumType
  volMedian: SortEnumType
  volMedianPct: SortEnumType
  madPct: SortEnumType
  madSpot: SortEnumType
  d: SortEnumType
  g: SortEnumType
  p: SortEnumType
  v: SortEnumType
  d0: SortEnumType
  g0: SortEnumType
  p0: SortEnumType
  v0: SortEnumType
  gD: PredictorPairSortInput
  pD: PredictorPairSortInput
  pG: PredictorPairSortInput
  pV: PredictorPairSortInput
  vD: PredictorPairSortInput
  vG: PredictorPairSortInput
}

input StringOperationFilterInput {
  and: [StringOperationFilterInput!]
  or: [StringOperationFilterInput!]
  eq: String
  neq: String
  contains: String
  ncontains: String
  in: [String]
  nin: [String]
  startsWith: String
  nstartsWith: String
  endsWith: String
  nendsWith: String
}

input UpdatePgQueryDtoInput {
  name: String
  content: String
}

enum ApplyPolicy {
  BEFORE_RESOLVER
  AFTER_RESOLVER
}

enum BacktestDays {
  D10
  D21
  D42
  D200
}

enum EarningsType {
  UNKNOWN
  REPORTED
  CONFIRMED
}

enum KindOfPredictorPair {
  AGGREGATE
  PD
  PG
  PV
  VD
  VG
  GD
}

enum MadType {
  MEAN
  MEDIAN
}

enum ReportTime {
  UNKNOWN
  PREMARKET
  POSTMARKET
}

enum SortEnumType {
  ASC
  DESC
}

directive @authorize("The name of the authorization policy that determines access to the annotated resource." policy: String "Roles that are allowed to access the annotated resource." roles: [String!] "Defines when when the resolver shall be executed.By default the resolver is executed after the policy has determined that the current user is allowed to access the field." apply: ApplyPolicy! = BEFORE_RESOLVER) repeatable on SCHEMA | OBJECT | FIELD_DEFINITION

"The \`@defer\` directive may be provided for fragment spreads and inline fragments to inform the executor to delay the execution of the current fragment to indicate deprioritization of the current fragment. A query with \`@defer\` directive will cause the request to potentially return multiple responses, where non-deferred data is delivered in the initial response and data deferred is delivered in a subsequent response. \`@include\` and \`@skip\` take precedence over \`@defer\`."
directive @defer("If this argument label has a value other than null, it will be passed on to the result of this defer directive. This label is intended to give client applications a way to identify to which fragment a deferred result belongs to." label: String "Deferred when true." if: Boolean) on FRAGMENT_SPREAD | INLINE_FRAGMENT

"The \`@specifiedBy\` directive is used within the type system definition language to provide a URL for specifying the behavior of custom scalar definitions."
directive @specifiedBy("The specifiedBy URL points to a human-readable specification. This field will only read a result for scalar types." url: String!) on SCALAR

"The \`@stream\` directive may be provided for a field of \`List\` type so that the backend can leverage technology such as asynchronous iterators to provide a partial list in the initial response, and additional list items in subsequent responses. \`@include\` and \`@skip\` take precedence over \`@stream\`."
directive @stream("If this argument label has a value other than null, it will be passed on to the result of this stream directive. This label is intended to give client applications a way to identify to which fragment a streamed result belongs to." label: String "The initial elements that shall be send down to the consumer." initialCount: Int! = 0 "Streamed when true." if: Boolean) on FIELD

scalar Any

"The \`DateTime\` scalar represents an ISO-8601 compliant date time type."
scalar DateTime @specifiedBy(url: "https:\/\/www.graphql-scalars.com\/date-time")

"The built-in \`Decimal\` scalar type."
scalar Decimal

"The \`Long\` scalar type represents non-fractional signed whole 64-bit numeric values. Long can represent values between -(2^63) and 2^63 - 1."
scalar Long`

export default schema