import React, { useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { useApolloClient } from '@apollo/client'
import { Button, Checkbox, IconButton, Snackbar } from '@mui/material'
import { Assignment, Close } from '@mui/icons-material'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { useLocalStorage } from '../../Hooks'

function Clipboard({ text }) {
    const [open, setOpen] = useState(false)

    const handleOpen = () => setOpen(true)

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }

        setOpen(false)
    }

    const action = (
        <React.Fragment>
            <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
                <Close fontSize="small" />
            </IconButton>
        </React.Fragment>
    )

    return (
        <>
            <CopyToClipboard text={text} onCopy={handleOpen}>
                <Assignment style={{ cursor: 'pointer' }} fontSize="small" />
            </CopyToClipboard>

            <Snackbar open={open} autoHideDuration={2000} onClose={handleClose} message="Copied to clipboard" action={action} />
        </>
    )
}

function Profile({ accessToken }) {
    const apolloClient = useApolloClient()
    const { user, logout } = useAuth0()
    const [autoLogin, setAutoLogin] = useLocalStorage('login.autoLogin', false)

    function kleptLogout() {
        apolloClient.resetStore()
        logout({ returnTo: window.location.origin })
    }

    return (
        <div
            style={{
                padding: '15px',
            }}
        >
            <table style={{ padding: '10px 0' }}>
                <tbody>
                    <tr>
                        <td>Username</td>
                        <td>{user.name}</td>
                    </tr>
                    <tr>
                        <td>Email</td>
                        <td>{user.email}</td>
                    </tr>
                    <tr>
                        <td>Auto-login</td>
                        <td>
                            <Checkbox checked={autoLogin} onChange={(e) => setAutoLogin(e.target.checked)} />
                        </td>
                    </tr>
                    <tr>
                        <td>Access Token</td>
                        <td>
                            <Clipboard text={accessToken} />
                        </td>
                    </tr>
                    <tr>
                        <td>Build SHA</td>
                        <td></td>
                    </tr>
                </tbody>
            </table>
            <Button onClick={() => kleptLogout()} style={{ fontSize: '18px' }} variant="contained">
                Logout
            </Button>
        </div>
    )
}

export default Profile
