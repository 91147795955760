Number.prototype.toPercent = function (decimalPlaces) {
    return (this * 100).toFixed(decimalPlaces || 0).toString() + '%'
}

Number.prototype.toBigNumber = function (decimalPlaces) {
    const suffixs = ['K', 'M', 'B', 'T']
    let x = this
    let y = this.toString()
    for (let i = 0; i < suffixs.length; i++) {
        if (Math.abs(x) >= 1000) {
            x = x / 1000
            y = x.toFixed(decimalPlaces).toString() + suffixs[i]
        }
    }
    return y
}
