import React, { useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import Button from '@mui/material/Button'
import { Typography } from '@mui/material'
import { useLocalStorage } from '../Hooks'
import { SpinnerCenterLg } from '../Spinner'

function LoginPage() {
    const { loginWithRedirect } = useAuth0()
    const [redirectUrl, setRedirectUrl] = useLocalStorage('login.redirectUrl', null)
    const [autoLogin, setAutoLogin] = useLocalStorage('login.autoLogin', false)
    const [didAutoLogin, setDidAutoLogin] = useState(false)

    function sendToAuth0() {
        if (window?.location?.pathname) {
            console.log('setRedirectUrl to -> ', window.location.pathname)
            setRedirectUrl(window.location.pathname)
        }
        loginWithRedirect()
    }

    if (!didAutoLogin && autoLogin) {
        setDidAutoLogin(true)
        sendToAuth0()
    }

    if (autoLogin) {
        return (
            <div
                style={{
                    position: 'absolute',
                    height: '100%',
                    width: '100%',
                    top: '0',
                    left: '0',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    margin: '0',
                    padding: '0',
                }}
            >
                <SpinnerCenterLg />
            </div>
        )
    }

    return (
        <div
            style={{
                position: 'absolute',
                height: '100%',
                width: '100%',
                top: '0',
                left: '0',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                margin: '0',
                padding: '0',
            }}
        >
            <div style={{ width: 'auto' }}>
                <Button
                    onClick={sendToAuth0}
                    variant="outlined"
                    style={{
                        width: '350px',
                        height: '200px',
                        fontSize: '36px',
                    }}
                >
                    Login
                </Button>
            </div>
            <div
                style={{
                    position: 'absolute',
                    left: '5px',
                    top: '5px',
                    color: '#ddd',
                }}
            >
                <Typography>klept.io</Typography>
            </div>
            <div
                style={{
                    position: 'absolute',
                    right: '5px',
                    bottom: '5px',
                    color: '#ddd',
                }}
            >
                <Typography>klept.io</Typography>
            </div>
        </div>
    )
}

export default LoginPage
