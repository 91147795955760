import React, { useState } from 'react'
import { Line, Scatter } from 'react-chartjs-2'
import { useQuery, gql } from '@apollo/client'
import { Box, Grid, Card, CardContent, Typography, Slider } from '@mui/material'
import { Add as AddIcon, Remove as RemoveIcon } from '@mui/icons-material'

import Spinner from '../../Spinner'

function RiskReportChart({ data, title, format, formatPrecise, color, dateRange, hoverIndex, setHoverIndex }) {
    const dataToUse = data.filter((x, index) => index >= dateRange[0] && index <= dateRange[1])
    const [isMouseOnChart, setIsMouseOnChart] = useState(false)

    color = color || '#262525'
    const lineData = {
        labels: dataToUse.map((x) => x.date.format('MMM yy')),
        datasets: [
            {
                label: title,
                borderColor: color,
                borderWidth: 2,
                pointRadius: 0.01,
                pointHitRadius: 8,
                data: dataToUse.map((x) => x.value),
            },
        ],
    }

    const lineOptions = {
        isMouseOnChart,
        hoverIndex,
        scales: {
            y: {
                ticks: {
                    callback: format,
                },
            },
            x: {
                ticks: {
                    autoSkip: true,
                    maxTicksLimit: 10,
                },
            },
        },
        animation: {
            duration: 0,
        },
        plugins: {
            legend: {
                display: false,
            },
            title: {
                display: title,
                text: title,
                color: color,
                font: {
                    family: 'Roboto, sans-serif',
                },
            },
            tooltip: {
                callbacks: {
                    title: function (t) {
                        var value = dataToUse[t[0].dataIndex]
                        return value.date.format('MM/dd/yyyy')
                    },
                    label: function (t) {
                        var value = t.dataset.data[t.dataIndex]
                        return (formatPrecise || format)(value)
                    },
                },
            },
        },
    }

    const plugins = [
        {
            afterDraw: function (chart) {
                function drawLine({ x1, y1, x2, y2 }, dashed) {
                    chart.ctx.save()
                    if (dashed) chart.ctx.setLineDash([6, 4])
                    chart.ctx.beginPath()
                    chart.ctx.moveTo(x1, y1)
                    chart.ctx.lineTo(x2, y2)
                    chart.ctx.lineWidth = 1
                    chart.ctx.strokeStyle = '#1C2128'
                    chart.ctx.stroke()
                    chart.ctx.restore()
                }

                const isMouseOnChart = chart.config.options.isMouseOnChart
                let index = chart.config.options.hoverIndex

                if (isMouseOnChart) {
                    if (chart._active.length > 0) {
                        index = chart._active[0].index
                        setHoverIndex(index)
                    } else {
                        index = null
                        setHoverIndex(null)
                    }
                }

                if (index) {
                    const topY = chart.scales.y.top
                    const bottomY = chart.scales.y.bottom
                    const leftX = chart.scales.x.left
                    const rightX = chart.scales.x.right

                    const dataset = chart.getDatasetMeta(0).data
                    const x = dataset[index].x
                    const y = dataset[index].y

                    drawLine({ x1: leftX, x2: rightX, y1: y, y2: y }, true)
                    drawLine({ x1: x, x2: x, y1: topY, y2: bottomY }, true)
                }
            },
        },
    ]

    return (
        <Grid item sm={12} md={10} lg={6} sx={{ p: 1 }}>
            <Box>
                <Line
                    data={lineData}
                    options={lineOptions}
                    style={{ maxHeight: '300px' }}
                    plugins={plugins}
                    onMouseEnter={() => setIsMouseOnChart(true)}
                    onMouseLeave={() => setIsMouseOnChart(false)}
                ></Line>
            </Box>
        </Grid>
    )
}

function RiskDashboard({ data }) {
    // approx 365 days (260 trading days) of range
    const defaultRange = [data.length - 260, data.length - 1]
    const [dateRange, setDateRange] = useState(defaultRange)
    const [hoverIndex, setHoverIndex] = useState(null)

    const yesterday = data[data.length - 2]
    const today = data[data.length - 1]

    const spxPctChg = today.spx / yesterday.spx - 1

    const TopCard = ({ title, content }) => (
        <Grid item xs={12} sm={6} md={3} xl={2}>
            <Card>
                <CardContent>
                    <Typography variant="h5" component="div">
                        {title}
                    </Typography>
                    <Typography sx={{ mb: 1.5 }} color="text.secondary">
                        {content}
                    </Typography>
                </CardContent>
            </Card>
        </Grid>
    )

    return (
        <>
            <Grid container spacing={2} alignItems="center" justifyContent="center" sx={{ p: 1 }}>
                <TopCard title="Last Refresh" content={today.date.format('M/d/yyyy')} />
                <TopCard title="SPX" content={`${today.spx.toFixed(2)} (${spxPctChg < 0 ? '' : '+'}${spxPctChg.toPercent(2)})`} />
                <TopCard title="NPD" content={today.npd.toFixed(2)} />
                <TopCard title="VGR" content={today.vgr.toFixed(2)} />
                <TopCard title="CR(x)" content={today.cr.toFixed(2)} />
            </Grid>
            <Grid container spacing={2} alignItems="start" justifyContent="center" sx={{ pt: 5 }}>
                <Box
                    sx={{
                        width: {
                            lg: '50%',
                            md: '85%',
                            sm: '100%',
                        },
                        mx: 'auto',
                    }}
                >
                    <Slider
                        size="small"
                        min={0}
                        max={data.length - 1}
                        defaultValue={defaultRange}
                        onChangeCommitted={(e, v) => setDateRange(v)}
                        valueLabelFormat={(x) => data[x].date.format('M/d/yyyy')}
                        valueLabelDisplay="auto"
                    ></Slider>
                </Box>
            </Grid>
            <Grid container spacing={2} alignItems="start" justifyContent="center" sx={{ p: 1 }}>
                <RiskReportChart
                    title="SPX"
                    format={(val) => val.toFixed(0)}
                    formatPrecise={(val) => val.toFixed(0)}
                    data={data.map((x) => {
                        return { ...x, value: x.spx }
                    })}
                    dateRange={dateRange}
                    hoverIndex={hoverIndex}
                    setHoverIndex={setHoverIndex}
                />
                <RiskReportChart
                    title="VIX"
                    format={(val) => val.toFixed(0)}
                    formatPrecise={(val) => val.toFixed(2)}
                    data={data.map((x) => {
                        return { ...x, value: x.vix }
                    })}
                    dateRange={dateRange}
                    hoverIndex={hoverIndex}
                    setHoverIndex={setHoverIndex}
                />
                <RiskReportChart
                    title="NPD"
                    color="#e6b31a"
                    format={(val) => val.toFixed(0)}
                    formatPrecise={(val) => val.toFixed(2)}
                    data={data.map((x) => {
                        return { ...x, value: x.npd }
                    })}
                    dateRange={dateRange}
                    hoverIndex={hoverIndex}
                    setHoverIndex={setHoverIndex}
                />
                <RiskReportChart
                    title="VGR"
                    color="#9e5bc6"
                    format={(val) => val.toFixed(0)}
                    formatPrecise={(val) => val.toFixed(2)}
                    data={data.map((x) => {
                        return { ...x, value: x.vgr }
                    })}
                    dateRange={dateRange}
                    hoverIndex={hoverIndex}
                    setHoverIndex={setHoverIndex}
                />
                <RiskReportChart
                    title="CR(x)"
                    format={(val) => val.toFixed(0)}
                    formatPrecise={(val) => val.toFixed(2)}
                    data={data.map((x) => {
                        return { ...x, value: x.cr }
                    })}
                    dateRange={dateRange}
                    hoverIndex={hoverIndex}
                    setHoverIndex={setHoverIndex}
                />
            </Grid>
        </>
    )
}

function RiskReport() {
    const { loading, error, data } = useQuery(
        gql`
            query {
                gex(order: { date: ASC }) {
                    date
                    spx
                    vix
                    npd
                    cr
                    vgr
                }
            }
        `
    )

    if (data) {
        return <RiskDashboard data={data.gex} />
    }

    if (loading) {
        return (
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                <Spinner></Spinner>
            </Box>
        )
    }

    return <Box></Box>
}

export default RiskReport
