// Calculate line of best fit => https://www.varsitytutors.com/hotmath/hotmath_help/topics/line-of-best-fit
// data: array[{ x: number, y: number }]
// returns { m: slopeOfLine, b: yInterceptOfLine }
function CalcLineOfBestFit(data) {
    let xSum = 0
    let ySum = 0

    data.forEach((d) => {
        xSum += d.x
        ySum += d.y
    })

    const xMean = xSum / data.length
    const yMean = ySum / data.length

    // Slope of line of best fit:
    // SUM( (x - xMean)(y - yMean) ) / SUM( (x - xMean)^2 )
    let numeratorSum = 0
    let denominatorSum = 0

    data.forEach((d) => {
        const xDiff = d.x - xMean
        const yDiff = d.y - yMean

        numeratorSum += xDiff * yDiff
        denominatorSum += xDiff * xDiff
    })

    const slope = numeratorSum / denominatorSum
    const yIntercept = yMean - slope * xMean

    return {
        m: slope,
        b: yIntercept,
    }
}

export { CalcLineOfBestFit }
