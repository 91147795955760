import React from 'react'

function Gql() {
    return (
        <iframe
            frameBorder="0"
            allowFullScreen
            style={{
                width: '100%',
                height: '100%',
                position: 'absolute',
                top: '0',
                left: '0',
            }}
            src={'/graphql'}
            title="graphql"
        ></iframe>
    )
}

export default Gql
