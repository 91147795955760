import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery, gql } from '@apollo/client'
import { Line } from 'react-chartjs-2'
import {
    Box,
    Checkbox,
    List,
    ListItem,
    ListItemText,
    Grid,
    Typography,
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableCell,
    TableRow,
    Slider,
} from '@mui/material'
import { SpinnerCenterLg } from '../../Spinner'
import { useLocalStorage } from '../../Hooks'

function SqzmeReport({ ticker, data, earningsDates }) {
    const allDatasetsDefault = {
        ScoreAvgR: { enabled: true, color: '#000000' },
        ScoreAvgMad: { enabled: true, color: '#000000' },
        Fwd5dMean: { enabled: true, color: '#4682B4' },
        MeanR10D: { enabled: false, color: '#4682B4' },
        MeanR21D: { enabled: true, color: '#1E90FF' },
        MeanR42D: { enabled: true, color: '#6A5ACD' },
        MeanR200D: { enabled: false, color: '#483D8B' },
        Fwd5dMedian: { enabled: false, color: '#FF0000' },
        MedianR10D: { enabled: false, color: '#FA8072' },
        MedianR21D: { enabled: false, color: '#CD5C5C' },
        MedianR42D: { enabled: false, color: '#DC143C' },
        MedianR200D: { enabled: false, color: '#8B0000' },
    }

    const [dateRange, setDateRange] = useLocalStorage('s.sqzme.dateRange', { id: '1Y', daysToAdd: -365 })
    const [showEarnings, setShowEarnings] = useLocalStorage('s.sqzme.showEarnings', true)
    const [allDatasets, setAllDatasets] = useLocalStorage('s.sqzme.allDatasets', allDatasetsDefault)

    function toggleDataset(id) {
        const ds = allDatasets[id] || allDatasetsDefault[id]
        const newDatasets = { ...allDatasets }
        newDatasets[id] = {
            ...ds,
            enabled: !ds.enabled,
        }
        setAllDatasets(newDatasets)
    }

    function DateRangeItem({ id, daysToAdd }) {
        function updateDateRange() {
            setDateRange({ id, daysToAdd })
        }
        return (
            <Box
                sx={{
                    p: '0 3px',
                    m: '0 3px',
                    borderStyle: 'solid',
                    borderWidth: '2px',
                    borderRadius: '4px',
                    borderColor: dateRange.id === id ? 'primary.main' : '#ddd',
                    color: dateRange.id === id ? 'primary.main' : '#ddd',
                    cursor: 'pointer',
                }}
                onClick={() => updateDateRange()}
            >
                {id}
            </Box>
        )
    }

    function DatasetListItem({ id, name }) {
        const ds = allDatasets[id] || allDatasetsDefault[id]

        return (
            <ListItem sx={{ p: 0 }}>
                <ListItemText>
                    <Checkbox size="small" checked={ds.enabled} onChange={() => toggleDataset(id)} style={{ color: ds.color }} /> {name}
                </ListItemText>
            </ListItem>
        )
    }

    function DatasetTableItem({ id }) {
        const ds = allDatasets[id] || allDatasetsDefault[id]

        return (
            <TableCell>
                <Checkbox checked={ds.enabled} onChange={() => toggleDataset(id)} size="small" style={{ color: ds.color }} />
            </TableCell>
        )
    }

    function ShowEarnings() {
        function toggle(event) {
            const newChecked = event.target.checked
            setShowEarnings(newChecked)
        }

        return (
            <ListItem sx={{ p: 0 }}>
                <ListItemText>
                    <Checkbox checked={showEarnings} onChange={toggle} size="small" /> Show Earnings
                </ListItemText>
            </ListItem>
        )
    }

    let dateRangeStart = new Date()
    dateRangeStart = dateRangeStart.setDate(dateRangeStart.getDate() + dateRange.daysToAdd)

    const dataWithDateRange = data.filter((x) => x.date > dateRangeStart)

    const { data: quandlData } = useQuery(
        gql`
            query getQuandlTickerInfo($ticker: String) {
                quandlTicker(where: { ticker: { eq: $ticker } }) {
                    name
                    exchange
                }
            }
        `,
        {
            variables: {
                ticker: ticker,
            },
        }
    )

    return (
        <Box
            sx={{
                height: 'calc(100% - 16px)',
                p: '0 8px',
            }}
        >
            <Grid container spacing={0} alignItems="top" justifyContent="center" sx={{ height: '100%', margin: '8px 0' }}>
                <Grid item md={2} xs={12}>
                    <Grid item xs={12}>
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <DateRangeItem id="30d" daysToAdd={-30} />
                            <DateRangeItem id="90d" daysToAdd={-90} />
                            <DateRangeItem id="1Y" daysToAdd={-365} />
                            <DateRangeItem id="2Y" daysToAdd={-365 * 2} />
                        </Box>
                    </Grid>
                    <List>
                        <ShowEarnings />
                        <DatasetListItem id="ScoreAvgR" name="Scored Avg R-Value" />
                        <DatasetListItem id="ScoreAvgMad" name="Forward 5d Avg" />
                        <DatasetListItem id="Fwd5dMean" name="Forward 5d Mean" />
                        <DatasetListItem id="Fwd5dMedian" name="Forward 5d Median" />
                    </List>
                    <TableContainer>
                        <Table
                            size="small"
                            sx={{
                                '& td:first-child': { textAlign: 'right' },
                                '& td, & th': { textAlign: 'center', padding: '0' },
                                width: '100%',
                            }}
                        >
                            <TableHead>
                                <TableRow>
                                    <TableCell />
                                    <TableCell>10d</TableCell>
                                    <TableCell>21d</TableCell>
                                    <TableCell>42d</TableCell>
                                    <TableCell>200d</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell>Mean</TableCell>
                                    <DatasetTableItem id="MeanR10D" />
                                    <DatasetTableItem id="MeanR21D" />
                                    <DatasetTableItem id="MeanR42D" />
                                    <DatasetTableItem id="MeanR200D" />
                                </TableRow>
                                <TableRow>
                                    <TableCell>Median</TableCell>
                                    <DatasetTableItem id="MedianR10D" />
                                    <DatasetTableItem id="MedianR21D" />
                                    <DatasetTableItem id="MedianR42D" />
                                    <DatasetTableItem id="MedianR200D" />
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                <Grid item md={10} xs={12}>
                    <Typography variant="h4">{ticker}</Typography>
                    <Typography variant="span">{quandlData?.quandlTicker?.length >= 1 ? quandlData.quandlTicker[0].name : ''}</Typography>
                    <SqzmeMainChart
                        data={dataWithDateRange}
                        earningsDates={earningsDates}
                        enabledDatasets={allDatasets}
                        showEarnings={showEarnings}
                    />
                </Grid>
            </Grid>
        </Box>
    )
}

function SqzmeMainChart({ data, earningsDates, enabledDatasets, showEarnings }) {
    const dataToUse = data

    const datasets = [
        {
            label: 'Closing Price',
            borderColor: '#262525',
            borderWidth: 2,
            pointRadius: 0,
            pointHitRadius: 4,
            yAxisID: 'price',
            data: dataToUse.map((x) => x.close),
        },
    ]

    if (enabledDatasets?.Fwd5dMean?.enabled) {
        datasets.push({
            label: 'Fwd 5d Mean',
            borderWidth: 0,
            pointRadius: 2,
            pointHitRadius: 4,
            pointBackgroundColor: enabledDatasets.Fwd5dMean.color,
            yAxisID: 'price',
            data: dataToUse.map((x) => x.close + x.madSpot * x.mean),
        })
    }

    if (enabledDatasets?.Fwd5dMedian?.enabled) {
        datasets.push({
            label: 'Fwd 5d Median',
            borderWidth: 0,
            pointRadius: 2,
            pointHitRadius: 4,
            pointBackgroundColor: enabledDatasets.Fwd5dMedian.color,
            yAxisID: 'price',
            data: dataToUse.map((x) => x.close + x.madSpot * x.median),
        })
    }

    if (enabledDatasets?.MeanR10D?.enabled) {
        datasets.push({
            label: 'R Value - Mean 10 Day',
            borderColor: enabledDatasets.MeanR10D.color,
            borderWidth: 1,
            pointRadius: 0,
            pointHitRadius: 4,
            yAxisID: 'scale01',
            data: dataToUse.map((x) => x.sqzmeBacktest?.meanRValue?.rValue10Day),
        })
    }

    if (enabledDatasets?.MeanR21D?.enabled) {
        datasets.push({
            label: 'R Value - Mean 21 Day',
            borderColor: enabledDatasets.MeanR21D.color,
            borderWidth: 1,
            pointRadius: 0,
            pointHitRadius: 4,
            yAxisID: 'scale01',
            data: dataToUse.map((x) => x.sqzmeBacktest?.meanRValue?.rValue21Day),
        })
    }

    if (enabledDatasets?.MeanR42D?.enabled) {
        datasets.push({
            label: 'R Value - Mean 42 Day',
            borderColor: enabledDatasets.MeanR42D.color,
            borderWidth: 1,
            pointRadius: 0,
            pointHitRadius: 4,
            yAxisID: 'scale01',
            data: dataToUse.map((x) => x.sqzmeBacktest?.meanRValue?.rValue42Day),
        })
    }

    if (enabledDatasets?.MeanR200D?.enabled) {
        datasets.push({
            label: 'R Value - Mean 200 Day',
            borderColor: enabledDatasets.MeanR200D.color,
            borderWidth: 1,
            pointRadius: 0,
            pointHitRadius: 4,
            yAxisID: 'scale01',
            data: dataToUse.map((x) => x.sqzmeBacktest?.meanRValue?.rValue200Day),
        })
    }

    if (enabledDatasets?.MedianR10D?.enabled) {
        datasets.push({
            label: 'R Value - 10 Day',
            borderColor: enabledDatasets.MedianR10D.color,
            borderWidth: 1,
            pointRadius: 0,
            pointHitRadius: 4,
            yAxisID: 'scale01',
            data: dataToUse.map((x) => x.sqzmeBacktest?.medianRValue?.rValue10Day),
        })
    }

    if (enabledDatasets?.MedianR21D?.enabled) {
        datasets.push({
            label: 'R Value - 21 Day',
            borderColor: enabledDatasets.MedianR21D.color,
            borderWidth: 1,
            pointRadius: 0,
            pointHitRadius: 4,
            yAxisID: 'scale01',
            data: dataToUse.map((x) => x.sqzmeBacktest?.medianRValue?.rValue21Day),
        })
    }

    if (enabledDatasets?.MedianR42D?.enabled) {
        datasets.push({
            label: 'R Value - Median 42 Day',
            borderColor: enabledDatasets.MedianR42D.color,
            borderWidth: 1,
            pointRadius: 0,
            pointHitRadius: 4,
            yAxisID: 'scale01',
            data: dataToUse.map((x) => x.sqzmeBacktest?.medianRValue?.rValue42Day),
        })
    }

    if (enabledDatasets?.MedianR200D?.enabled) {
        datasets.push({
            label: 'R Value - Median 200 Day',
            borderColor: enabledDatasets.MedianR200D.color,
            borderWidth: 1,
            pointRadius: 0,
            pointHitRadius: 4,
            yAxisID: 'scale01',
            data: dataToUse.map((x) => x.sqzmeBacktest?.medianRValue?.rValue200Day),
        })
    }

    if (enabledDatasets?.ScoreAvgMad?.enabled) {
        datasets.push({
            label: 'Fwd 5d Avg Return',
            borderWidth: 0,
            pointRadius: 2,
            pointHitRadius: 4,
            pointBackgroundColor: enabledDatasets.Fwd5dMean.color,
            yAxisID: 'price',
            data: dataToUse.map((x) => x.close + x.madSpot * x.sqzmeBacktest?.scoreAvgMad),
        })
    }

    if (enabledDatasets?.ScoreAvgR?.enabled) {
        datasets.push({
            label: 'R Value - Scored Avg',
            borderColor: enabledDatasets.ScoreAvgR.color,
            borderWidth: 1,
            pointRadius: 0,
            pointHitRadius: 4,
            yAxisID: 'scale01',
            data: dataToUse.map((x) => x.sqzmeBacktest?.scoreAvgR),
        })
    }

    const showEarningsPlugin = {
        // https://github.com/reactchartjs/react-chartjs-2/issues/383#issuecomment-875676241
        afterDraw: (chart) => {
            const configOptions = chart.config.options

            if (!configOptions.showEarnings) return

            const topY = chart.scales.price.top
            const bottomY = chart.scales.price.bottom

            const earningsDates = configOptions.earningsDates
            const dataToUse = configOptions.dataToUse

            const datesMap = {}
            earningsDates.forEach((d) => (datesMap[d.date] = true))

            const dataset = chart.getDatasetMeta(0).data

            const { ctx } = chart

            dataset.forEach((item, index) => {
                const dataPoint = dataToUse[index]
                if (dataPoint.date in datesMap) {
                    const x = item.x

                    // draw vertical line
                    ctx.save()
                    ctx.setLineDash([2, 3])
                    ctx.beginPath()
                    ctx.moveTo(x, topY)
                    ctx.lineTo(x, bottomY)
                    ctx.lineWidth = 1
                    ctx.strokeStyle = '#1C2128'
                    ctx.stroke()
                    ctx.restore()
                }
            })
        },
    }

    const plugins = [showEarningsPlugin]

    const lineData = {
        labels: dataToUse.map((x) => x.date.format('yyyy-MM-dd')),
        datasets: datasets,
    }

    const lineOptions = {
        showEarnings,
        dataToUse,
        earningsDates,
        scales: {
            price: {
                position: 'left',
                ticks: {
                    callback: (val) => val.toFixed(0),
                },
            },
            scale01: {
                position: 'right',
                min: -1,
                max: 1,
                ticks: {
                    callback: (val) => val.toFixed(1),
                },
                title: {
                    display: false,
                },
                grid: {
                    display: false,
                },
            },
        },
        animation: {
            duration: 0,
        },
        plugins: {
            legend: {
                display: false,
            },
            title: {
                display: false,
            },
            tooltip: {
                callbacks: {
                    label: function (t) {
                        var index = t.dataIndex
                        var value = t.dataset.data[index]
                        return value.toFixed(2)
                    },
                },
            },
        },
    }

    return <Line data={lineData} options={lineOptions} plugins={plugins} style={{ height: '100%', height: '100%' }} />
}

function SqzmeWrapper() {
    const { symbol } = useParams()

    const twoYearsAgo = new Date()
    twoYearsAgo.setUTCFullYear(twoYearsAgo.getUTCFullYear() - 2)
    twoYearsAgo.setDate(twoYearsAgo.getDate() - 5)
    const dateFilter = `${twoYearsAgo.format('yyyy-MM-dd')}T00:00:00.000Z`

    const { loading, error, data } = useQuery(
        gql`
            query ($ticker: String!, $date: DateTime!) {
                sqzme(where: { ticker: { eq: $ticker }, date: { gt: $date } }, order: { date: ASC }) {
                    date
                    close
                    median
                    mean
                    madSpot
                    sqzmeBacktest {
                        fwd5dPct
                        fwd5dMad
                        scoreAvgR
                        scoreAvgMad
                        medianRValue {
                            rValue10Day
                            rValue21Day
                            rValue42Day
                            rValue200Day
                        }
                        meanRValue {
                            rValue10Day
                            rValue21Day
                            rValue42Day
                            rValue200Day
                        }
                        pG_MedianRValue {
                            rValue10Day
                            rValue21Day
                            rValue42Day
                            rValue200Day
                        }
                        pG_MeanRValue {
                            rValue10Day
                            rValue21Day
                            rValue42Day
                            rValue200Day
                        }
                        pV_MedianRValue {
                            rValue10Day
                            rValue21Day
                            rValue42Day
                            rValue200Day
                        }
                        pV_MeanRValue {
                            rValue10Day
                            rValue21Day
                            rValue42Day
                            rValue200Day
                        }
                        pD_MedianRValue {
                            rValue10Day
                            rValue21Day
                            rValue42Day
                            rValue200Day
                        }
                        pD_MeanRValue {
                            rValue10Day
                            rValue21Day
                            rValue42Day
                            rValue200Day
                        }
                    }
                }
            }
        `,
        {
            variables: {
                ticker: symbol,
                date: dateFilter,
            },
        }
    )

    const { data: earningsData } = useQuery(
        gql`
            query getEarnings($ticker: String) {
                earnings(where: { ticker: { eq: $ticker } }) {
                    date
                }
            }
        `,
        {
            variables: {
                ticker: symbol,
            },
        }
    )

    if (loading) {
        return <SpinnerCenterLg />
    } else if (data) {
        return <SqzmeReport ticker={symbol} data={data.sqzme} earningsDates={earningsData?.earnings || []} />
    } else {
        return <div>Error: {error.toString()}</div>
    }
}

export default SqzmeWrapper
