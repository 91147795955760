import { useState, useEffect } from 'react'

function getStorageValue(key, defaultValue) {
    try {
        const saved = localStorage.getItem(key)
        if (saved === null) return defaultValue
        const initial = JSON.parse(saved)
        return initial
    } catch {
        return defaultValue
    }
}

export const useLocalStorage = (key, defaultValue) => {
    const [value, setValue] = useState(() => getStorageValue(key, defaultValue))
    useEffect(() => localStorage.setItem(key, JSON.stringify(value)), [key, value])

    return [value, setValue]
}
