import React, { useState } from 'react'
import { Line, Scatter } from 'react-chartjs-2'
import { useQuery, gql } from '@apollo/client'
import { Box, Grid, Card, CardContent, Typography, Slider } from '@mui/material'
import { Add as AddIcon, Remove as RemoveIcon } from '@mui/icons-material'

import Spinner from '../../Spinner'
import { CalcLineOfBestFit } from '../../Utils'

function DixFwdReturnScatter({ data }) {
    const [forwardPeriod, setForwardPeriod] = useState(21)

    let dixReturns = data.map((d) => {
        return { ...d, forwardSpx: null }
    })

    for (let i = 0; i < data.length; i++) {
        const lookbackIndex = i - forwardPeriod

        if (lookbackIndex < 0) continue

        dixReturns[lookbackIndex].forwardSpx = dixReturns[i].spx
    }

    dixReturns = dixReturns.filter((x) => x.forwardSpx)

    const plugins = [
        {
            afterDraw: function (chart) {
                // Take a dataset y-coordinate and map it to the
                // render context y-coordinate
                function convertYCoordinate(y) {
                    const scale = chart.scales.y
                    const topY = scale.top
                    const bottomY = scale.bottom
                    const endY = scale.end
                    const startY = scale.start

                    const slope = (topY - bottomY) / (endY - startY)
                    const yIntercept = slope * -endY + topY

                    return slope * y + yIntercept
                }

                // Take a dataset x-coordinate and map it to the
                // render context x-coordinate
                function convertXCoordinate(x) {
                    const scale = chart.scales.x
                    const leftX = scale.left
                    const rightX = scale.right
                    const endX = scale.end
                    const startX = scale.start

                    const slope = (rightX - leftX) / (endX - startX)
                    const yIntercept = slope * -endX + rightX

                    return slope * x + yIntercept
                }

                // x-axis
                const dixToday = chart.config.options.dixToday
                const dixX = convertXCoordinate(dixToday)

                const verticalLine = {
                    x1: dixX,
                    y1: chart.scales.y.bottom,
                    x2: dixX,
                    y2: chart.scales.y.top,
                }

                function drawLine({ x1, y1, x2, y2 }, dashed) {
                    chart.ctx.save()
                    if (dashed) chart.ctx.setLineDash([6, 4])
                    chart.ctx.beginPath()
                    chart.ctx.moveTo(x1, y1)
                    chart.ctx.lineTo(x2, y2)
                    chart.ctx.lineWidth = 1
                    chart.ctx.strokeStyle = '#1C2128'
                    chart.ctx.stroke()
                    chart.ctx.restore()
                }

                drawLine(verticalLine)

                const lobf = chart.config.options.lineOfBestFit
                const lineOfBestFit = {
                    x1: convertXCoordinate(chart.scales.x.start),
                    y1: convertYCoordinate(lobf.m * chart.scales.x.start + lobf.b),
                    x2: convertXCoordinate(chart.scales.x.end),
                    y2: convertYCoordinate(lobf.m * chart.scales.x.end + lobf.b),
                }

                drawLine(lineOfBestFit, true)

                const lobfEvalDate = lobf.m * dixToday + lobf.b

                const horizontalLine = {
                    x1: chart.scales.x.left,
                    y1: convertYCoordinate(lobfEvalDate),
                    x2: chart.scales.x.right,
                    y2: convertYCoordinate(lobfEvalDate),
                }

                drawLine(horizontalLine)
            },
        },
    ]

    const chartData = dixReturns.map((d) => {
        return {
            x: d.dix,
            y: d.forwardSpx / d.spx - 1,
        }
    })

    const scatterData = {
        datasets: [
            {
                data: chartData,
            },
        ],
    }

    const scatterOptions = {
        dixToday: data[data.length - 1].dix,
        lineOfBestFit: CalcLineOfBestFit(chartData),
        scales: {
            x: {
                min: 0.32,
                max: 0.48,
                ticks: {
                    callback: (val) => val.toPercent(0),
                },
            },
            y: {
                min: -0.06,
                max: 0.06,
                ticks: {
                    callback: (val) => val.toPercent(0),
                },
            },
        },
        animation: {
            duration: 0,
        },
        plugins: {
            legend: {
                display: false,
            },
            title: {
                display: true,
                text: 'DIX to ' + forwardPeriod + 'd forward return',
                font: {
                    family: 'Roboto, sans-serif',
                },
            },
            tooltip: {
                callbacks: {
                    label: function (t) {
                        var index = t.dataIndex
                        var value = t.dataset.data[index]
                        return `(${value.x.toPercent(2)}, ${value.y.toPercent(2)})`
                    },
                },
            },
        },
    }

    return (
        <Grid item sm={12} md={10} lg={6}>
            <Scatter data={scatterData} options={scatterOptions} plugins={plugins} style={{ maxHeight: '300px' }} />
            <Box sx={{ mt: 2, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <RemoveIcon sx={{ cursor: 'pointer' }} onClick={() => setForwardPeriod(Math.max(1, forwardPeriod - 1))} />
                {forwardPeriod}d
                <AddIcon sx={{ cursor: 'pointer' }} onClick={() => setForwardPeriod(Math.min(40, forwardPeriod + 1))} />
            </Box>
        </Grid>
    )
}

function DixGexRatio({ data, forwardPeriod }) {
    let dixGexReturns = data.map((d) => {
        return { ...d, forwardSpx: null }
    })

    for (let i = 0; i < data.length; i++) {
        const lookbackIndex = i - forwardPeriod

        if (lookbackIndex < 0) continue

        dixGexReturns[lookbackIndex].forwardSpx = dixGexReturns[i].spx
    }

    dixGexReturns = dixGexReturns.filter((x) => x.forwardSpx)

    const plugins = [
        {
            afterDraw: function (chart) {
                // Take a dataset y-coordinate and map it to the
                // render context y-coordinate
                function convertYCoordinate(y) {
                    const scale = chart.scales.y
                    const topY = scale.top
                    const bottomY = scale.bottom
                    const endY = scale.end
                    const startY = scale.start

                    const slope = (topY - bottomY) / (endY - startY)
                    const yIntercept = slope * -endY + topY

                    return slope * y + yIntercept
                }

                // Take a dataset x-coordinate and map it to the
                // render context x-coordinate
                function convertXCoordinate(x) {
                    const scale = chart.scales.x
                    const leftX = scale.left
                    const rightX = scale.right
                    const endX = scale.end
                    const startX = scale.start

                    const slope = (rightX - leftX) / (endX - startX)
                    const yIntercept = slope * -endX + rightX

                    return slope * x + yIntercept
                }

                // x-axis
                const dixToday = chart.config.options.dixToday
                const dixX = convertXCoordinate(dixToday)

                // y-axis
                const gexToday = chart.config.options.gexToday
                const gexY = convertYCoordinate(gexToday)

                const horizontalLine = {
                    x1: chart.scales.x.left,
                    y1: gexY,
                    x2: chart.scales.x.right,
                    y2: gexY,
                }
                const verticalLine = {
                    x1: dixX,
                    y1: chart.scales.y.bottom,
                    x2: dixX,
                    y2: chart.scales.y.top,
                }

                function drawLine({ x1, y1, x2, y2 }) {
                    chart.ctx.save()
                    chart.ctx.setLineDash([6, 4])
                    chart.ctx.beginPath()
                    chart.ctx.moveTo(x1, y1)
                    chart.ctx.lineTo(x2, y2)
                    chart.ctx.lineWidth = 1
                    chart.ctx.strokeStyle = '#1C2128'
                    chart.ctx.stroke()
                    chart.ctx.restore()
                }

                drawLine(horizontalLine)
                drawLine(verticalLine)
            },
        },
    ]

    const scatterData = {
        datasets: [
            {
                pointBackgroundColor: function (context) {
                    var index = context.dataIndex
                    var value = context.dataset.data[index]

                    if (value.r < 0) {
                        let opacity = '0.6'
                        let color = '255'
                        if (value.r > -0.05) {
                            const ratio = value.r / -0.05
                            opacity = (ratio * 0.6).toFixed(3).toString()
                            color = (ratio * 255).toFixed(0).toString()
                        }
                        return 'rgba(255, 0, 0, ' + opacity + ')'
                    }
                    if (value.r > 0) {
                        let opacity = '0.6'
                        let color = '255'
                        if (value.r < 0.05) {
                            const ratio = value.r / 0.05
                            opacity = (ratio * 0.6).toFixed(3).toString()
                            color = (ratio * 255).toFixed(0).toString()
                        }
                        return 'rgba(0, 0, ' + color + ', ' + opacity + ')'
                    }
                    return 'rgba(0, 0, 0, 0)'
                },
                data: dixGexReturns.map((d) => {
                    return {
                        x: d.dix,
                        y: d.gex,
                        r: d.forwardSpx / d.spx - 1,
                    }
                }),
            },
        ],
    }

    const billion = 1000000000
    const scatterOptions = {
        dixToday: data[data.length - 1].dix,
        gexToday: data[data.length - 1].gex,
        scales: {
            x: {
                min: 0.38,
                max: 0.48,
                ticks: {
                    callback: (val) => val.toPercent(0),
                },
            },
            y: {
                min: -2 * billion,
                max: 16 * billion,
                ticks: {
                    callback: (val) => val.toBigNumber(0),
                },
            },
        },
        animation: {
            duration: 0,
        },
        plugins: {
            legend: {
                display: false,
            },
            title: {
                display: true,
                text: 'DIX/GEX Ratio to fwd. 1mo SPX return',
                font: {
                    family: 'Roboto, sans-serif',
                },
            },
            tooltip: {
                callbacks: {
                    label: function (t) {
                        var index = t.dataIndex
                        var value = t.dataset.data[index]
                        return `(${value.x.toPercent(2)}, ${value.y.toBigNumber(1)}) ${value.r >= 0 ? '+' : ''}${value.r.toPercent(2)}`
                    },
                },
            },
        },
    }

    return (
        <Grid item sm={12} md={10} lg={6}>
            <Scatter data={scatterData} options={scatterOptions} plugins={plugins} style={{ maxHeight: '300px' }} />
        </Grid>
    )
}

function DixChart({ data, title, format, formatPrecise, color }) {
    // approx 365 days (260 trading days) of range
    const defaultChartRange = [data.length - 260, data.length - 1]
    const [chartDateRange, setChartDateRange] = useState(defaultChartRange)

    const dataToUse = data.filter((x, index) => index >= chartDateRange[0] && index <= chartDateRange[1])

    color = color || '#262525'
    const lineData = {
        labels: dataToUse.map((x) => x.date.format('MMM yy')),
        datasets: [
            {
                label: title,
                borderColor: color,
                borderWidth: 2,
                pointRadius: 0.01,
                pointHitRadius: 8,
                data: dataToUse.map((x) => x.value),
            },
        ],
    }

    const lineOptions = {
        scales: {
            y: {
                ticks: {
                    callback: format,
                },
            },
            x: {
                ticks: {
                    autoSkip: true,
                    maxTicksLimit: 10,
                },
            },
        },
        animation: {
            duration: 0,
        },
        plugins: {
            legend: {
                display: false,
            },
            title: {
                display: title,
                text: title,
                color: color,
                font: {
                    family: 'Roboto, sans-serif',
                },
            },
            tooltip: {
                callbacks: {
                    title: function (t) {
                        var value = dataToUse[t[0].dataIndex]
                        return value.date.format('MM/dd/yyyy')
                    },
                    label: function (t) {
                        var value = t.dataset.data[t.dataIndex]
                        return (formatPrecise || format)(value)
                    },
                },
            },
        },
    }

    return (
        <Grid item sm={12} md={10} lg={6} sx={{ p: 1 }}>
            <Box>
                <Line data={lineData} options={lineOptions} style={{ maxHeight: '300px' }}></Line>
            </Box>
            <Box sx={{ width: '80%', mx: 'auto' }}>
                <Slider
                    size="small"
                    min={0}
                    max={data.length - 1}
                    defaultValue={defaultChartRange}
                    onChangeCommitted={(e, v) => setChartDateRange(v)}
                    valueLabelFormat={(x) => data[x].date.format('M/d/yyyy')}
                    valueLabelDisplay="auto"
                ></Slider>
            </Box>
        </Grid>
    )
}

function DixDashboard({ data }) {
    const yesterday = data[data.length - 2]
    const today = data[data.length - 1]

    const spxPctChg = today.spx / yesterday.spx - 1

    const TopCard = ({ title, content }) => (
        <Grid item xs={12} sm={6} md={3} xl={2}>
            <Card>
                <CardContent>
                    <Typography variant="h5" component="div">
                        {title}
                    </Typography>
                    <Typography sx={{ mb: 1.5 }} color="text.secondary">
                        {content}
                    </Typography>
                </CardContent>
            </Card>
        </Grid>
    )

    return (
        <>
            <Grid container spacing={2} alignItems="center" justifyContent="center" sx={{ p: 1 }}>
                <TopCard title="Last Refresh" content={today.date.format('M/d/yyyy')} />
                <TopCard title="SPX" content={`${today.spx.toFixed(2)} (${spxPctChg < 0 ? '' : '+'}${spxPctChg.toPercent(2)})`} />
                <TopCard title="DIX" content={today.dix.toPercent(2)} />
                <TopCard title="GEX" content={today.gex.toBigNumber(2)} />
            </Grid>
            <Grid container spacing={2} alignItems="start" justifyContent="center" sx={{ p: 1 }}>
                <DixChart
                    title="DIX"
                    color="#1e7794"
                    format={(val) => val.toPercent(0)}
                    formatPrecise={(val) => val.toPercent(2)}
                    data={data.map((x) => {
                        return { ...x, value: x.dix }
                    })}
                />
                <DixChart
                    title="GEX"
                    color="#e6b31a"
                    format={(val) => val.toBigNumber(0)}
                    formatPrecise={(val) => val.toBigNumber(2)}
                    data={data.map((x) => {
                        return { ...x, value: x.gex }
                    })}
                />
                <DixFwdReturnScatter data={data} />
                <DixGexRatio data={data} forwardPeriod={21} />
            </Grid>
        </>
    )
}

function Dix() {
    const { loading, error, data } = useQuery(
        gql`
            query {
                dix(order: { date: ASC }) {
                    date
                    spx
                    dix
                    gex
                }
            }
        `
    )

    if (data) {
        return <DixDashboard data={data.dix} />
    }

    if (loading) {
        return (
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                <Spinner></Spinner>
            </Box>
        )
    }

    return <Box></Box>
}

export default Dix
