import React, { useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { buildSchema } from 'graphql'
import { ApolloClient, InMemoryCache, ApolloProvider, createHttpLink, ApolloLink } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { withScalars } from 'apollo-link-scalars'
import { DateTimeResolver } from 'graphql-scalars'
import FixDatesApolloLink from '../FixDatesApolloLink'
import schema from '../schema'

import LoadingPage from './LoadingPage'
import LoginPage from './LoginPage'
import AppCore from '../AppCore/AppCore'

let AccessToken = null

const apolloLinks = ApolloLink.from([
    setContext((_, { headers }) => {
        return {
            headers: {
                ...headers,
                authorization: AccessToken ? `Bearer ${AccessToken}` : '',
            },
        }
    }),
    FixDatesApolloLink,
    withScalars({
        schema: buildSchema(schema),
        typesMap: {
            DateTime: DateTimeResolver,
        },
    }),
    createHttpLink({
        uri: '/graphql',
    }),
])

const client = new ApolloClient({
    link: apolloLinks,
    credentials: 'same-origin',
    cache: new InMemoryCache(),
})

function AppAuth() {
    const { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0()
    const [accessToken, setAccessToken] = useState(null)

    if (isAuthenticated && accessToken) {
        return (
            <ApolloProvider client={client}>
                <AppCore accessToken={accessToken} />
            </ApolloProvider>
        )
    } else if (isAuthenticated && !accessToken) {
        getAccessTokenSilently().then((x) => {
            AccessToken = x
            setAccessToken(x)
        })
        return <LoadingPage />
    } else if (isLoading) {
        return <LoadingPage />
    } else {
        return <LoginPage />
    }
}

export default AppAuth
