import { useLocalStorage } from '../../Hooks'
import { Box, Button, ToggleButton, ToggleButtonGroup, Modal, TextField } from '@mui/material'
import { Refresh as RefreshIcon, Close as CloseIcon, Search as SearchIcon } from '@mui/icons-material'
import { useState } from 'react'
import { useMutation, gql } from '@apollo/client'

const SQZME_RISK = '/api/sqzme/risk'
const SQZME_PROBABILITY = '/api/sqzme/probability'
const SQZME_SENTIMENT = '/api/sqzme/sentiment'
const SQZME_APPENDIX = '/api/sqzme/appendix'

function GetSqzmeTickerReportPath(ticker) {
    return `/api/s/${ticker}/sqzme/pdf`
}

function SearchModal({ isOpen, setIsOpen, setReportUrl }) {
    const [search, setSearch] = useState('')

    const boxStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        borderRadius: 2.5,
        boxShadow: 24,
        p: 2.5,
    }

    return (
        <Modal open={isOpen} onClose={() => setIsOpen(false)}>
            <Box sx={boxStyle}>
                <Button sx={{ position: 'absolute', left: '0', top: '0' }} onClick={() => setIsOpen(false)}>
                    <CloseIcon fontSize="small"></CloseIcon>
                </Button>
                <Box sx={{ height: '15px' }}></Box>
                <TextField
                    value={search}
                    sx={{ width: '100%' }}
                    variant="outlined"
                    label="Symbol Search"
                    autoFocus
                    onFocus={(event) => event.target.select()}
                    onChange={(event) => {
                        const { value } = event.target
                        let newValue = value?.toUpperCase() || ''
                        newValue = newValue.replace(/[^a-zA-Z]/gm, '')
                        setSearch(newValue)
                    }}
                    onKeyDown={(event) => {
                        if (event.key == 'Enter' && search) {
                            setReportUrl(GetSqzmeTickerReportPath(search))
                            setIsOpen(false)
                        }
                    }}
                ></TextField>
            </Box>
        </Modal>
    )
}

function SqzmePdfs({ accessToken }) {
    const [selectedReportUrl, setSelectedReportUrl] = useLocalStorage('sqzme.pdfs.selectedReportV2', '/api/sqzme/probability')
    const [refreshIter, setRefreshIter] = useState(0)
    const [searchOpen, setSearchOpen] = useState(false)

    const [runClearCacheMutation, _] = useMutation(gql`
        mutation {
            clearCache
        }
    `)

    function changeReport(event, newValue) {
        if (newValue === 'search') {
            setSearchOpen(true)
        } else {
            setSelectedReportUrl(newValue)
        }
    }

    async function clearCache() {
        await runClearCacheMutation()
        setRefreshIter(refreshIter + 1)
    }

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                position: 'absolute',
                left: 0,
                top: 0,
                height: '100%',
                width: '100%',
                margin: 0,
                padding: 0,
                alignItems: 'stretch',
                alignContent: 'stretch',
            }}
        >
            <SearchModal isOpen={searchOpen} setIsOpen={setSearchOpen} setReportUrl={setSelectedReportUrl} />
            <Box sx={{ display: 'flex', padding: 2, justifyContent: 'center', alignItems: 'center' }}>
                <ToggleButtonGroup color="primary" value={selectedReportUrl} exclusive onChange={changeReport}>
                    <ToggleButton value={SQZME_RISK}>Risk</ToggleButton>
                    <ToggleButton value={SQZME_PROBABILITY}>Probability</ToggleButton>
                    <ToggleButton value={SQZME_SENTIMENT}>Sentiment</ToggleButton>
                    <ToggleButton value={SQZME_APPENDIX}>Appendix</ToggleButton>
                    <ToggleButton value={GetSqzmeTickerReportPath('SPY')}>SPY</ToggleButton>
                    <ToggleButton value={GetSqzmeTickerReportPath('TSLA')}>TSLA</ToggleButton>
                    <ToggleButton value="search">
                        <SearchIcon />
                    </ToggleButton>
                </ToggleButtonGroup>
                <Button sx={{ p: 0 }} onClick={() => clearCache()}>
                    <RefreshIcon />
                </Button>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flex: '1 1 auto',
                    position: 'relative',
                    alignItems: 'center',
                    justifyContent: 'center',
                    margin: 0,
                    padding: '0 0 5px 0',
                }}
            >
                <embed
                    key={`iter-${refreshIter}`}
                    frameBorder="0"
                    type="application/pdf"
                    allowFullScreen
                    style={{
                        width: '100%',
                        height: '100%',
                        maxWidth: '1200px',
                        marign: 'auto',
                    }}
                    src={selectedReportUrl + '?access_token=' + accessToken}
                />
            </Box>
        </Box>
    )
}

export default SqzmePdfs
