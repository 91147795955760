import React from 'react'
import { Link } from 'react-router-dom'
import { Box, Typography } from '@mui/material'
import {
    Home as HomeIcon,
    ShowChart as ShowChartIcon,
    TableChart as TableChartIcon,
    Storage as StorageIcon,
    Person as PersonIcon,
    Code as CodeIcon,
    Close as CloseIcon,
    Menu as MenuIcon,
    Search as SearchIcon,
} from '@mui/icons-material'

function HomeIconButton({ icon, title, to }) {
    return (
        <Box
            sx={{
                margin: 4,
                flexBasis: '0',
                flex: '1 1 0px',
                '& a': {
                    display: 'flex',
                    height: '100%',
                    width: '100%',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    color: 'primary.main',
                    textDecoration: 'none',
                    width: '100%',
                    height: '100%',
                },
            }}
        >
            <Link
                to={to}
                style={{
                    borderRadius: '16px',
                }}
            >
                <Box
                    sx={{
                        height: '100%',
                        width: '100%',
                        textAlign: 'center',
                        border: '1px solid #eee',
                        borderRadius: 4,
                        padding: 2,
                        '&:hover': {
                            transition: 'background-color 50ms linear',
                            backgroundColor: '#1976d233',
                        },
                    }}
                >
                    <Typography variant="h6">{title}</Typography>
                    {React.createElement(icon, { sx: { fontSize: '2rem' } })}
                </Box>
            </Link>
        </Box>
    )
}

function Home() {
    return (
        <Box sx={{ height: '80%', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
            <Box sx={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                <HomeIconButton icon={StorageIcon} title="Hangfire" to="/hf" />
                <HomeIconButton icon={CodeIcon} title="GraphQL" to="/gql" />
                <HomeIconButton icon={CodeIcon} title="Postgres" to="/pg" />
                <HomeIconButton icon={PersonIcon} title="Profile" to="/profile" />
            </Box>
            <Box sx={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                <HomeIconButton icon={MenuIcon} title="Stock Scanner" to="/sqzme/scanner" />
                <HomeIconButton icon={ShowChartIcon} title="DIX" to="/sqzme/dix" />
                <HomeIconButton icon={ShowChartIcon} title="GEX+" to="/sqzme/gex" />
                <HomeIconButton icon={ShowChartIcon} title="Risk Report" to="/sqzme/risk" />
            </Box>
        </Box>
    )
}

export default Home
