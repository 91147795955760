import React from 'react'
import { Box } from '@mui/material'

function Spinner({ width, height }) {
    /* By Sam Herbert (@sherb), for everyone. More @ http://goo.gl/7AJzbL */
    return (
        <svg width={width || 38} height={height || 38} viewBox="0 0 38 38" xmlns="http://www.w3.org/2000/svg" fill="#247ba0">
            <defs>
                <linearGradient x1="8.042%" y1="0%" x2="65.682%" y2="23.865%" id="a">
                    <stop stopColor="#247ba0" stopOpacity="0" offset="0%" />
                    <stop stopColor="#247ba0" stopOpacity=".631" offset="63.146%" />
                    <stop stopColor="#247ba0" offset="100%" />
                </linearGradient>
            </defs>
            <g fill="none" fillRule="evenodd">
                <g transform="translate(1 1)">
                    <path d="M36 18c0-9.94-8.06-18-18-18" id="Oval-2" stroke="url(#a)" strokeWidth="1">
                        <animateTransform attributeName="transform" type="rotate" from="0 18 18" to="360 18 18" dur="0.9s" repeatCount="indefinite" />
                    </path>
                    <circle fill="#fff" cx="36" cy="18" r="1">
                        <animateTransform attributeName="transform" type="rotate" from="0 18 18" to="360 18 18" dur="0.9s" repeatCount="indefinite" />
                    </circle>
                </g>
            </g>
        </svg>
    )
}

export default Spinner

export function SpinnerCenter({ width, height }) {
    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', width: '100%' }}>
            <Spinner height={width} width={height} />
        </Box>
    )
}

export function SpinnerCenterLg() {
    return <SpinnerCenter width={70} height={70} />
}
