import React, { useEffect, useState } from 'react'
import Editor from '@monaco-editor/react'
import { Box, Grid, Button, Typography, List, ListItem, Menu, MenuItem, Modal, TextField } from '@mui/material'
import { useQuery, useApolloClient, gql } from '@apollo/client'
import Spinner from '../../Spinner'
import { styled } from '@mui/system'
import {
    KeyboardArrowDown as ChevronDownIcon,
    ChevronRight as ChevronRightIcon,
    Close as CloseIcon,
    Add as AddIcon,
    Circle as CircleIcon,
    Edit as EditIcon,
} from '@mui/icons-material'
import { useLocalStorage } from '../../Hooks'

const gqlQuery = gql`
    query SqlQuery($sqlQuery: String!) {
        sqlQuery(statement: $sqlQuery) {
            columns {
                name
                ordinal
                dataType
            }
            rows
            isSuccessful
            errorMessage
        }
    }
`

function SqlQueryError({ errors }) {
    return (
        <List sx={{ height: '100%', width: '100%' }}>
            {errors.map((x, index) => (
                <ListItem key={index}>
                    <Typography>{x.extensions?.message || x.message || 'Unknown Error'}</Typography>
                </ListItem>
            ))}
        </List>
    )
}

const CustomScrollbarBox = styled(Box)({
    scrollbarColor: '#eee #fff',
    scrollbarWidth: 'thin',
    '&::-webkit-scrollbar': {
        width: '10px',
    },
    '&::-webkit-scrollbar-track': {
        background: '#fff',
        borderLeft: '1px solid #eee',
    },
    '&::-webkit-scrollbar-thumb': {
        background: '#eee',
    },
})

const ResultsTable = styled('table')({
    maxWidth: '100%',
    borderCollapse: 'collapse',
    '& td, & th': {
        fontSize: '11px',
        fontFamily: 'Roboto, sans-serif',
    },
    '& td': {
        padding: '1px 4px',
        border: '1px solid #eee',
    },
    '& th > div': {
        padding: '1px 4px',
        height: '100%',
        borderBottom: '1px solid #eee',
    },
})

const ResultsTh = ({ children }) => (
    <th>
        <div>{children}</div>
    </th>
)

function formatDataItem(item) {
    if (item == null) {
        return <span style={{ fontStyle: 'italic' }}>NULL</span>
    }

    if (typeof item === 'object') {
        return <span>{JSON.stringify(item)}</span>
    }

    return <span>{item.toString()}</span>
}

function SqlQueryResults({ data }) {
    if (data.isSuccessful) {
        return (
            <CustomScrollbarBox sx={{ height: '100%', width: '100%', overflowY: 'scroll' }}>
                <ResultsTable>
                    <thead style={{ position: 'sticky', top: '0', backgroundColor: 'white' }}>
                        <tr>
                            <ResultsTh>#</ResultsTh>
                            {data.columns.map((x) => (
                                <ResultsTh key={x.name}>{x.name}</ResultsTh>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {data.rows
                            .filter((x, rowInd) => rowInd < 3000)
                            .map((x, rowInd) => (
                                <tr key={rowInd}>
                                    <td key={`${rowInd}-rownum`}>{rowInd + 1}</td>
                                    {x.map((y, colInd) => (
                                        <td key={`${rowInd}-${colInd}`}>{formatDataItem(y)}</td>
                                    ))}
                                </tr>
                            ))}
                    </tbody>
                </ResultsTable>
            </CustomScrollbarBox>
        )
    } else {
        return (
            <Box sx={{ height: '100%', width: '100%', overflowY: 'scroll' }}>
                <span style={{ fontSize: '11px', fontFamily: 'Roboto, sans-serif' }}>{data.errorMessage}</span>
            </Box>
        )
    }
}

function SchemaItem({ table_name, columns }) {
    const [isExpanded, setIsExpanded] = useState(false)

    return (
        <Box sx={{ padding: '2px 2px 1px 2px', borderBottom: '1px solid #eee', cursor: 'pointer' }} onClick={() => setIsExpanded(!isExpanded)}>
            <Typography sx={{ fontFamily: 'Roboto Mono, monospace', fontWeight: '700', fontSize: '12px' }}>
                {isExpanded ? (
                    <ChevronDownIcon sx={{ fontSize: '13px', cursor: 'pointer', verticalAlign: 'middle' }} />
                ) : (
                    <ChevronRightIcon sx={{ fontSize: '13px', cursor: 'pointer' }} />
                )}{' '}
                {table_name}
            </Typography>
            {isExpanded
                ? columns.map((x) => (
                      <Box key={x.column_name} sx={{ padding: '2px' }}>
                          <Typography sx={{ fontFamily: 'Roboto Mono, monospace', fontWeight: '400', fontSize: '11px' }}>
                              • {x.column_name} ({x.data_type})
                          </Typography>
                      </Box>
                  ))
                : null}
        </Box>
    )
}

function FileItem({ fileName, isSelected, openFile, renameQuery, deleteQuery }) {
    const [contextMenu, setContextMenu] = React.useState(null)

    function handleContextMenu(event) {
        event.preventDefault()
        setContextMenu(
            contextMenu === null
                ? {
                      mouseX: event.clientX - 2,
                      mouseY: event.clientY - 4,
                  }
                : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
                  // Other native context menus might behave different.
                  // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
                  null
        )
    }

    function handleContextMenuClose() {
        setContextMenu(null)
    }

    return (
        <Box
            key={fileName}
            sx={{
                padding: '2px 2px',
                margin: '1px 0',
                cursor: 'pointer',
                backgroundColor: isSelected ? 'rgba(25, 118, 210, 0.04)' : null,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                '&:hover': { backgroundColor: 'rgba(25, 118, 210, 0.04)' },
            }}
            onClick={() => openFile(fileName)}
            onContextMenu={handleContextMenu}
        >
            <Typography sx={{ fontFamily: 'Roboto Mono, monospace', fontWeight: '700', fontSize: '13px' }}>{fileName}</Typography>
            <Menu
                open={contextMenu !== null}
                onClose={handleContextMenuClose}
                anchorReference="anchorPosition"
                anchorPosition={contextMenu !== null ? { top: contextMenu.mouseY, left: contextMenu.mouseX } : undefined}
            >
                <MenuItem
                    onClick={() => {
                        renameQuery()
                        handleContextMenuClose()
                    }}
                >
                    Rename File
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        deleteQuery()
                        handleContextMenuClose()
                    }}
                >
                    Delete File
                </MenuItem>
            </Menu>
        </Box>
    )
}

function LeftBar({ schema, files, currentTabName, openFile, renameQuery, deleteQuery }) {
    const fileNames = Object.keys(files)
    fileNames.sort((a, b) => (a > b ? 1 : b > a ? -1 : 0))

    return (
        <CustomScrollbarBox
            style={{
                height: '100%',
                width: '199px',
                borderRight: '1px solid #eee',
                overflowY: 'auto',
            }}
        >
            <Box>
                {schema.map((x) => (
                    <SchemaItem key={x.table_name} table_name={x.table_name} columns={x.columns} />
                ))}
            </Box>
            <Box sx={{ borderTop: '2px solid #eee', padding: '0 2px' }}>
                <Typography sx={{ fontFamily: 'Roboto, sans-serif', fontWeight: 'bold', fontSize: '14px', textDecoration: 'underline' }}>
                    Files
                </Typography>
                <Box>
                    {fileNames.map((fileName) => {
                        const file = files[fileName]
                        return (
                            <FileItem
                                key={file.fileName}
                                fileName={file.fileName}
                                isSelected={currentTabName === file.fileName}
                                openFile={openFile}
                                renameQuery={() => renameQuery(file.fileName)}
                                deleteQuery={() => deleteQuery(file.fileName)}
                            />
                        )
                    })}
                </Box>
            </Box>
        </CustomScrollbarBox>
    )
}

function TabComponent({ tabIndex, name, showSaveIndicator, isActive, isLoading, saveQuery, onClick, onClose, renameQuery, deleteQuery, closeTab }) {
    const [contextMenu, setContextMenu] = React.useState(null)

    function stripSqlExtension(s) {
        if (s?.toLowerCase().endsWith('.sql')) {
            return s.substr(0, s.length - 4)
        }
        return s
    }

    function handleContextMenu(event) {
        event.preventDefault()
        setContextMenu(
            contextMenu === null
                ? {
                      mouseX: event.clientX - 2,
                      mouseY: event.clientY - 4,
                  }
                : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
                  // Other native context menus might behave different.
                  // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
                  null
        )
    }

    function handleContextMenuClose() {
        setContextMenu(null)
    }

    const [isHover, setIsHover] = useState(false)
    return (
        <Box
            onMouseDown={onClick}
            onMouseEnter={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}
            onContextMenu={handleContextMenu}
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
                width: 'auto',
                padding: '0 5px',
                fontSize: '0.875rem',
                fontWeight: '500',
                color: isActive ? 'primary.main' : '#bbb',
                borderRight: '1px solid #eee',
                cursor: 'pointer',
                userSelect: 'none',
            }}
        >
            {showSaveIndicator ? <CircleIcon sx={{ marginRight: '5px', fontSize: '0.7em' }} /> : null}
            {stripSqlExtension(name?.toUpperCase())}
            <Box sx={{ display: 'flex', pl: '5px', width: '25px', alignItems: 'center', justifyContent: 'center' }}>
                {isLoading ? <Spinner height={16} width={16} /> : null}
                {!isLoading && (isActive || isHover) ? (
                    <CloseIcon
                        sx={{ fontSize: '14px', ':hover': { backgroundColor: '#eee' } }}
                        onClick={(e) => {
                            if (onClose) onClose(e)
                        }}
                    />
                ) : null}
            </Box>
            <Menu
                open={contextMenu !== null}
                onClose={handleContextMenuClose}
                anchorReference="anchorPosition"
                anchorPosition={contextMenu !== null ? { top: contextMenu.mouseY, left: contextMenu.mouseX } : undefined}
            >
                {showSaveIndicator && (
                    <MenuItem
                        onClick={() => {
                            handleContextMenuClose()
                            saveQuery(tabIndex)
                        }}
                    >
                        Save
                    </MenuItem>
                )}
                <MenuItem
                    onClick={() => {
                        renameQuery()
                        handleContextMenuClose()
                    }}
                >
                    Rename File
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        deleteQuery()
                        handleContextMenuClose()
                    }}
                >
                    Delete File
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        closeTab()
                        handleContextMenuClose()
                    }}
                >
                    Close Tab
                </MenuItem>
            </Menu>
        </Box>
    )
}

class Tab {
    name
    content

    isLoading
    data
    error

    constructor(name, content) {
        this.name = name
        this.content = content
        this.isLoading = false
        this.data = null
        this.error = null
    }
}

function RenameFileModal({ renameFileModalFileName, setRenameFileModalFileName, renameQuery }) {
    const [newFileName, setNewFileName] = useState(renameFileModalFileName)

    function saveNewFileName() {
        if (newFileName && newFileName != renameFileModalFileName) {
            renameQuery(renameFileModalFileName, newFileName)
        }

        setRenameFileModalFileName(null)
    }

    useEffect(() => {
        setNewFileName(renameFileModalFileName)
    }, [renameFileModalFileName])

    return (
        <Modal
            open={renameFileModalFileName != null}
            onClose={() => {
                setRenameFileModalFileName(null)
            }}
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    bgcolor: 'background.paper',
                    borderRadius: 2.5,
                    boxShadow: 24,
                    p: 2.5,
                }}
            >
                <Button sx={{ position: 'absolute', top: '0', left: '0' }} onClick={() => setRenameFileModalFileName(null)}>
                    <CloseIcon fontSize="small"></CloseIcon>
                </Button>
                <Box sx={{ height: '15px' }}></Box>
                <TextField
                    defaultValue={renameFileModalFileName}
                    sx={{ width: '100%' }}
                    variant="outlined"
                    label="Enter a new name"
                    autoFocus
                    onFocus={(event) => event.target.select()}
                    onChange={(event) => {
                        const { value } = event.target
                        setNewFileName(value)
                    }}
                    onKeyDown={(event) => {
                        if (event.key == 'Enter' && newFileName) {
                            saveNewFileName()
                            event.preventDefault()
                        }
                    }}
                ></TextField>
            </Box>
        </Modal>
    )
}

function DeleteFileModal({ deleteFileModalFileName, setDeleteFileModalFileName, deleteQuery }) {
    function deleteFile() {
        deleteQuery(deleteFileModalFileName)
        setDeleteFileModalFileName(null)
    }

    return (
        <Modal
            open={deleteFileModalFileName != null}
            onClose={() => {
                setDeleteFileModalFileName(null)
            }}
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    bgcolor: 'background.paper',
                    borderRadius: 2.5,
                    boxShadow: 24,
                    p: 2.5,
                }}
            >
                <Typography>Are you sure you want to delete "{deleteFileModalFileName}"</Typography>
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'right' }}>
                    <Button onClick={deleteFile} autoFocus>
                        Delete
                    </Button>
                    <Button onClick={() => setDeleteFileModalFileName(null)}>Cancel</Button>
                </Box>
            </Box>
        </Modal>
    )
}

function PgQueryTool({ files, schema }) {
    const apolloClient = useApolloClient()

    const [editor, setEditor] = useState(null)
    const [monaco, setMonaco] = useState(null)

    const [tabs, setTabs] = useLocalStorage('pg.tabs', [new Tab('New Query.sql', '-- Enter your sql query here')])

    const [activeTab, setActiveTab] = useLocalStorage('pg.activeTab', 0)
    const [canRunQuery, setCanRunQuery] = useState(false)
    const [filesDict, setFiles] = useState({})
    const [renameFileModalFileName, setRenameFileModalFileName] = useState(null)
    const [deleteFileModalFileName, setDeleteFileModalFileName] = useState(null)

    // Initialize `filesDict` from `files`
    useEffect(() => {
        const newFilesDict = {}
        files.forEach((x) => (newFilesDict[x.fileName] = x))
        setFiles(newFilesDict)

        tabs.forEach((x) => {
            if (newFilesDict[x.name]) {
                x.content = newFilesDict[x.name].fileContent
            }
        })

        refreshTabs()
    }, [])

    useEffect(() => {
        const interval = setInterval(() => {
            if (editor) {
                const editorValue = editor.getValue()
                const stateValue = tabs[activeTab].content

                if (editorValue !== stateValue) {
                    tabs[activeTab].content = editorValue
                    refreshTabs()
                }
            }
        }, 1000)
        return () => clearInterval(interval)
    }, [activeTab, tabs])

    useEffect(() => {
        checkCanRunQuery()
    }, [editor])

    useEffect(() => {
        if (editor && monaco) {
            editor.addAction({
                id: 'run-query',
                label: 'Run Query',
                keybindings: [monaco.KeyMod.CtrlCmd | monaco.KeyCode.Enter, monaco.KeyMod.WinCtrl | monaco.KeyCode.Enter],
                run: function (editor) {
                    if (canRunQuery) {
                        runQuery()
                    }
                },
            })

            editor.addAction({
                id: 'save-file',
                label: 'Save File',
                keybindings: [monaco.KeyMod.CtrlCmd | monaco.KeyCode.KEY_S, monaco.KeyMod.WinCtrl | monaco.KeyCode.KEY_S],
                run: function (editor) {
                    saveQuery(activeTab)
                },
            })
        }
    }, [editor, monaco, canRunQuery, activeTab, tabs, filesDict])

    const [isDragging, setIsDragging] = useState(false)
    const [cursorYStart, setCursorYStart] = useState(null)
    const [dividerYStart, setDividerYStart] = useState(null)
    const [dividerPos, setDividerPos] = useState(0)

    const refreshTabs = () => setTabs([...tabs])

    function onMouseDown(evt) {
        setIsDragging(true)
        setCursorYStart(evt.clientY)
        setDividerYStart(dividerPos)
    }

    function onMouseMove(evt) {
        if (!isDragging) return
        const cursorYDelta = evt.clientY - cursorYStart
        setDividerPos(dividerYStart + cursorYDelta)
    }

    function onMouseUp(evt) {
        setIsDragging(false)
    }

    function activateTab(e, tabIndex) {
        if (e.button === 1) {
            closeTab(tabIndex)
        } else if (e.button === 0) {
            const editorContent = editor.getValue()
            tabs[activeTab].content = editorContent
            refreshTabs()
            setActiveTab(tabIndex)
        }
    }

    function openFile(fileName) {
        const existingTab = tabs.findIndex((x) => x.name === fileName)

        if (existingTab < 0) {
            const newTabs = [...tabs]
            const fileToOpen = filesDict[fileName]
            newTabs.push(new Tab(fileName, fileToOpen.fileContent))
            setTabs(newTabs)
            setActiveTab(newTabs.length - 1)
        } else {
            setActiveTab(existingTab)
        }
    }

    function closeTab(tabIndex) {
        let newTabs = [...tabs]
        newTabs.splice(tabIndex, 1)

        let newActiveTab = activeTab
        if (activeTab >= tabIndex) {
            newActiveTab -= 1
        }

        if (newActiveTab < 0 || newActiveTab >= newTabs.length) {
            newActiveTab = 0
        }

        if (newTabs.length < 1) {
            newTabs.push(new Tab(getNewQueryName(true, false), ''))
        }

        setActiveTab(newActiveTab)
        setTabs(newTabs)
    }

    function checkCanRunQuery() {
        const newVal = newCanRunQuery()

        if (newVal != canRunQuery) setCanRunQuery(newVal)

        function newCanRunQuery() {
            if (tabs[activeTab].isLoading) return false

            if (!editor) return false

            const editorContent = editor.getValue()
            if (!editorContent) return false

            return true
        }
    }

    function getNewQueryName(checkFiles, checkTabs) {
        let name = 'New Query.sql'
        let counter = 1
        while ((checkFiles && filesDict && filesDict[name]) || (checkTabs && tabs.find((x) => x.name == name))) {
            name = 'New Query (' + counter + ').sql'
            counter++
        }
        return name
    }

    async function runQuery() {
        const activeTabIndex = activeTab
        const editorContent = editor.getValue()

        tabs[activeTabIndex].content = editorContent
        tabs[activeTabIndex].isLoading = true
        tabs[activeTabIndex].data = null
        tabs[activeTabIndex].error = null

        refreshTabs()

        try {
            const result = await apolloClient.query({
                query: gqlQuery,
                variables: { sqlQuery: editorContent },
                fetchPolicy: 'network-only',
            })

            tabs[activeTabIndex].data = result.data
        } catch (e) {
            tabs.error = e.toString()
        } finally {
            tabs[activeTabIndex].isLoading = false

            refreshTabs()
        }
    }

    async function saveQuery(tabIndex) {
        const fileContent = editor.getValue()
        const fileName = tabs[tabIndex].name

        console.log('Save file:', { tabIndex, fileName, fileContent })

        const createQuery = gql`
            mutation createPgQuery($createPgQueryDto: CreatePgQueryDtoInput!) {
                createPgQuery(createPgQueryDto: $createPgQueryDto)
            }
        `

        const updateQuery = gql`
            mutation updatePgQuery($updatePgQueryDto: UpdatePgQueryDtoInput!) {
                updatePgQuery(updatePgQueryDto: $updatePgQueryDto)
            }
        `

        const fileExists = !!filesDict[fileName]

        let query, variables

        if (fileExists) {
            query = updateQuery
            variables = { updatePgQueryDto: { name: fileName, content: fileContent } }

            filesDict[fileName] = {
                ...filesDict[fileName],
                fileContent,
            }

            setFiles({ ...filesDict })
        } else {
            query = createQuery
            variables = { createPgQueryDto: { name: fileName, content: fileContent } }

            filesDict[fileName] = {
                fileName,
                fileContent,
            }
            setFiles({ ...filesDict })
        }

        tabs[tabIndex].content = fileContent
        refreshTabs()

        try {
            await apolloClient.mutate({
                mutation: query,
                variables,
            })
        } catch (e) {
            console.log(e)
        }
    }

    async function renameQuery(oldName, newName) {
        if (oldName in filesDict) {
            try {
                await apolloClient.mutate({
                    mutation: gql`
                        mutation renamePgQuery($oldName: String!, $newName: String!) {
                            renamePgQuery(oldName: $oldName, newName: $newName)
                        }
                    `,
                    variables: { oldName, newName },
                })
            } catch (e) {
                const errorMessage = e.networkError.result.errors[0].message
                console.error(errorMessage, e)
                return
            }

            const newFilesDict = {}
            Object.keys(filesDict).forEach((fileName) => {
                if (fileName != oldName) {
                    newFilesDict[fileName] = filesDict[fileName]
                }
            })
            newFilesDict[newName] = {
                ...filesDict[oldName],
                fileName: newName,
            }
            setFiles(newFilesDict)
        }

        tabs.forEach((x) => {
            if (x.name == oldName) x.name = newName
        })

        refreshTabs()
    }

    async function deleteQuery(name) {
        console.log('Delete file', name)
        if (name in filesDict) {
            try {
                await apolloClient.mutate({
                    mutation: gql`
                        mutation deletePgQuery($name: String!) {
                            deletePgQuery(name: $name)
                        }
                    `,
                    variables: { name },
                })
            } catch (e) {
                const errorMessage = e.networkError.result.errors[0].message
                console.error(errorMessage, e)
                return
            }

            const newFilesDict = {}
            Object.keys(filesDict).forEach((fileName) => {
                if (fileName != name) {
                    newFilesDict[fileName] = filesDict[fileName]
                }
            })
            setFiles(newFilesDict)
        }

        const tabIndex = tabs.findIndex((x) => x.name == name)
        if (tabIndex >= 0) {
            closeTab(tabIndex)
        }
    }

    function addNewTab() {
        tabs.push(new Tab(getNewQueryName(true, true), ''))
        setActiveTab(tabs.length - 1)
        refreshTabs()
    }

    function handleEditorDidMount(editor, monaco) {
        setEditor(editor)
        setMonaco(monaco)
    }

    return (
        <Box sx={{ height: '100%', width: '100%', display: 'flex' }}>
            <RenameFileModal
                renameFileModalFileName={renameFileModalFileName}
                setRenameFileModalFileName={setRenameFileModalFileName}
                renameQuery={renameQuery}
            />
            <DeleteFileModal
                deleteFileModalFileName={deleteFileModalFileName}
                setDeleteFileModalFileName={setDeleteFileModalFileName}
                deleteQuery={deleteQuery}
            />
            <LeftBar
                schema={schema}
                files={filesDict}
                currentTabName={tabs[activeTab].name}
                openFile={openFile}
                closeTab={closeTab}
                renameQuery={setRenameFileModalFileName}
                deleteQuery={setDeleteFileModalFileName}
            />
            <Grid container sx={{ height: '100%', width: 'calc(100% - 200px)' }} onMouseMove={onMouseMove} onMouseUp={onMouseUp}>
                <Grid item xs={12} sx={{ height: 'calc(50% - 1px + ' + dividerPos + 'px)', ...(isDragging && { userSelect: 'none' }) }}>
                    <Box
                        sx={{
                            height: '35px',
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'left',
                            borderBottom: '1px solid #eee',
                        }}
                    >
                        {tabs.map((x, ind) => {
                            const isActive = ind == activeTab
                            const tabContent = x.content
                            const fileContent = filesDict[x.name]?.fileContent

                            const hasChanges = tabContent != fileContent

                            return (
                                <TabComponent
                                    tabIndex={ind}
                                    saveQuery={saveQuery}
                                    key={ind}
                                    name={x.name}
                                    isActive={isActive}
                                    isLoading={x.isLoading}
                                    showSaveIndicator={hasChanges}
                                    renameQuery={() => setRenameFileModalFileName(tabs[ind].name)}
                                    deleteQuery={() => setDeleteFileModalFileName(tabs[ind].name)}
                                    closeTab={() => closeTab(ind)}
                                    onClick={(e) => activateTab(e, ind)}
                                    onClose={(e) => {
                                        e.stopPropagation()
                                        closeTab(ind)
                                    }}
                                />
                            )
                        })}
                        <Button onClick={addNewTab}>
                            <AddIcon />
                        </Button>
                    </Box>
                    <Editor
                        height="calc(100% - 35px)"
                        defaultLanguage="pgsql"
                        defaultValue=""
                        value={activeTab < tabs.length ? tabs[activeTab].content?.toString() : null}
                        onChange={() => {
                            checkCanRunQuery()
                        }}
                        onMount={handleEditorDidMount}
                    />
                </Grid>
                <Grid
                    item
                    xs={12}
                    sx={{ height: '3px', width: '100%', backgroundColor: 'primary.main', cursor: 'ns-resize' }}
                    onMouseDown={onMouseDown}
                />
                <Grid item xs={12} sx={{ height: 'calc(50% - 2px + ' + -dividerPos + 'px)', ...(isDragging && { userSelect: 'none' }) }}>
                    <Box sx={{ height: '36.5px', borderBottom: '1px solid #ccc' }}>
                        <Button disabled={!canRunQuery} sx={{ height: '100%' }} onClick={runQuery}>
                            Run Query
                        </Button>
                        {tabs[activeTab]?.data ? (
                            <Typography
                                sx={{
                                    display: 'inline-flex',
                                    height: '100%',
                                    fontSize: '0.875rem',
                                    padding: '6px 8px',
                                    fontWeight: '500',
                                    color: 'primary.main',
                                }}
                            >
                                ROWS: {tabs[activeTab].data.sqlQuery.rows.length}
                            </Typography>
                        ) : null}
                    </Box>
                    <Box sx={{ height: 'calc(100% - 37.5px)', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        {tabs[activeTab]?.data ? <SqlQueryResults data={tabs[activeTab].data.sqlQuery} /> : null}
                        {tabs[activeTab]?.isLoading ? <Spinner /> : null}
                        {tabs[activeTab]?.error ? <SqlQueryError errors={tabs[activeTab].error.graphQLErrors} /> : null}
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}

function PgQueryToolLoader() {
    const loadSchemaQuery = gql`
        query {
            sqlQuery(
                statement: "select table_name, column_name, data_type from information_schema.columns where table_schema = 'public' order by table_name, column_name"
            ) {
                rows
            }
        }
    `
    const { loading: schemaLoading, error: schemaError, data: schemaData } = useQuery(loadSchemaQuery)

    const loadFilesQuery = gql`
        query {
            pgQueries {
                fileName
                fileContent
            }
        }
    `
    const { loading: filesLoading, error: filesError, data: filesData } = useQuery(loadFilesQuery, { fetchPolicy: 'no-cache' })

    if (schemaLoading || filesLoading) {
        return (
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%' }}>
                <Spinner height={70} width={70} />
            </Box>
        )
    }
    if (schemaError) {
        return <Box>{schemaError.toString()}</Box>
    }
    if (filesError) {
        return <Box>{filesError.toString()}</Box>
    }
    if (schemaData) {
        return (
            <PgQueryTool
                schema={schemaData.sqlQuery.rows.reduce((result, x) => {
                    const table_name = x[0]
                    const column_name = x[1]
                    const data_type = x[2]

                    if (result.length < 1 || result[result.length - 1].table_name != table_name) {
                        result.push({ table_name, columns: [] })
                    }

                    const tbData = result[result.length - 1]
                    tbData.columns.push({
                        column_name,
                        data_type,
                    })
                    return result
                }, [])}
                files={filesData.pgQueries}
            />
        )
    }
}

export default PgQueryToolLoader
